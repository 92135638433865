import DataTable from "react-data-table-component";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";


// @mui/material components
import Grid from "@mui/material/Grid";

// core components
import LoadingPlaceHolder from "_components/Loading";

export function alphabeticalSort(field) {
  return (a, b) => {
    try {
      return a[field].localeCompare(b[field]);
    } catch (err) {
      return 0;
    }
  };
}

export function numericSort(field) {
  return (a, b) => {
    try {
      if (parseInt(a[field]) > parseInt(b[field])) {
        return 1;
      } else if (parseInt(a[field]) < parseInt(b[field])) {
        return -1;
      } else {
        return 0;
      }
    } catch (err) {
      return 0;
    }
  };
}

export function chronologicalSort(field) {
  return (a, b) => {
    try {
      if (new Date(a[field]) > new Date(b[field])) {
        return 1;
      } else if (new Date(a[field]) < new Date(b[field])) {
        return -1;
      } else {
        return 0;
      }
    } catch (err) {
      return 0;
    }
  };
}
const customStyles = {
  subHeader: {
    style: {
      padding: "0px"
    }
  },
  cells: {
    style: {
      fontSize: "12px" // fixme: move to a theme provided size
    }
  }
};

export default function Table(props) {
  const { t } = useTranslation("default");

  return (
    <DataTable
      paginationRowsPerPageOptions={props.rowsPerPageOptions}
      customStyles={customStyles}
      progressComponent={
        <LoadingPlaceHolder
          style={{ height: "600px" }}
          title={props.loadingText}
        />
      }
      noDataComponent={
        <Grid
          style={{
            height: props.height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          {props.noResultsMessage || t("error.noRecords")}
        </Grid>
      }
      subHeaderComponent={
        <Grid
          style={{
            flex: 1,
            display: "flex",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          {props.subHeaderComponent}
        </Grid>
      }
      persistTableHead
      {...props}
    />
  );
}

Table.defaultProps = {
  height: "600px",
  rowsPerPageOptions: [10,15,20,25,50]
};

Table.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  subHeaderComponent: PropTypes.element,
  loadingText: PropTypes.string,
  noResultsMessage: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};