const lockEditStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 10px 5%"
  },
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    borderRadius: "3px",
    marginBottom: "25px"
  },
  margin: {
    marginTop: "1%",
    marginBottom: "1%"
  },
  content: {
    marginTop: "20px"
  },
  description: {
    color: theme.lsyPalette.primary.mainLight
  },
  icon: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": "middle",
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  sizeBreak: {
    [theme.breakpoints.down("md")]: {width: "165px"},
    [theme.breakpoints.up("sm")]: {width: "300px"},
    [theme.breakpoints.up("md")]: {width: "400px"}
  },
  iconButton: {
    padding: "4px"
  }
});
  
export default lockEditStyle;