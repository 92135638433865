const userNotificationStyle = (theme) => ({
  body: {
    margin: `${theme.spacing(4)} ${theme.spacing(0.5)}`,
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.background,
    paddingBottom: theme.spacing(1.5)
  },
  title: {
    fontStyle: "bold",
    fontSize: theme.typography.largeFont.fontSize,
    marginTop: theme.spacing(1)
  },
  labels: {
    display: "flex"
  },
  notificationLabel: {
    "padding": "0",
    "margin": `0 ${theme.spacing(1)}`,
    "borderColor": theme.lsyPalette.footerText,
    "fontSize": theme.typography.smallFont.fontSize,
    "borderStyle": "solid solid none solid",
    "transition": "all 0.25s ease",
    "&:hover": {
      cursor: "pointer"
    }
  },
  notificationTab: {
    display: "flex", 
    alignItems: "center",
    margin: `0 ${theme.spacing(0.25)}`,
    padding: `0 ${theme.spacing(0.25)}`,
    width: "100%"
  },
  greenIcon: {
    "& circle": {
      color: theme.palette.greenColor.main
    }
  },
  notificationLabelActive: {
    backgroundColor: theme.background
  },
  notificationSettings: {
    width: "100%",
    borderRadius: `0 ${theme.spacing(0.3)} ${theme.spacing(0.3)} ${theme.spacing(0.3)}`,
    borderWidth: theme.spacing(0.1),
    borderColor: theme.lsyPalette.border.lightGrey,
    padding: theme.spacing(0.3),
    margin: `0 ${theme.spacing(1)}`
  },
  box: {
    position: "relative",
    marginTop: theme.spacing(2.5)
  },
  notificationWindow: {
    minHeight: "300px",
    marginBottom: theme.spacing(2),
    position: "relative",
    transition: "min-height 0.25s ease-out"
  },
  content: {
    minHeight: 0,
    height: 0,
    overflow: "hidden",
    transition: "min-height 0.2s ease-out"
  },
  header: {
    margin: `0 ${theme.spacing(1)}`
  },
  subTitle: {
    margin: `${theme.spacing(1)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
    fontSize: theme.typography.normalFont.fontSize
  },  
  descTitle: {
    fontSize: theme.typography.smallFont.fontSize,
    display: "flex",
    alignItems: "center",
    width: "fit-content"
  },
  description: {
    fontSize: theme.typography.smallFont.fontSize,
    margin: `0 ${theme.spacing(1)}`,
    marginBottom: theme.spacing(1)
  },
  extraMargin: {
    marginBottom: theme.spacing(4)
  },
  typeAhead: {
    "& .MuiGrid-root": {
      width: "100%"
    }
  },
  items: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
    display: "flex",
    flexWrap: "wrap"
  },
  itemContainer: {
    minHeight: "175px"
  },
  item: {
    fontSize: theme.typography.smallFont.fontSize,
    display: "flex",
    alignItems: "center",
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.5)}`,
    padding: `${theme.spacing(1)} ${theme.spacing(1.25)}`,
    width: "fit-content",
    height: "fit-content",
    border: "solid",
    borderWidth: "0.5px",
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.primary.light
  },
  itemText: {
    whiteSpace: "nowrap",
    maxWidth: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  removeItem: {
    fontSize: theme.typography.smallFont.fontSize,
    color: theme.lsyPalette.text
  },
  itemIcon: {
    fontSize: theme.typography.smallFont.fontSize,
    color: theme.lsyPalette.text,
    margin: `0 ${theme.spacing(0.5)}`
  },
  inputs: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  tabPanel: {
    [theme.breakpoints.up("sm")]: {
      position: "sticky",
      alignSelf: "flex-start",
      top: "10vh"
    },
    "& .Mui-disabled": { 
      opacity: 0.3
    },
    "& .MuiTab-root": {
      textTransform: "capitalize",
      padding: `0 ${theme.spacing(0.5)}`,
      margin: `0 ${theme.spacing(1)}`
    }
  },
  saveButton: {
    float: "right",
    backgroundColor: theme.lsyPalette.primary.mainDark
  },
  disabledText: {
    color: theme.lsyPalette.border.lightGrey
  },
  backContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    color: theme.lsyPalette.primary.mainLight,
    fontSize: theme.typography.smallFont.fontSize,
    width: "fit-content"
  },
  icon: {
    "color": theme.lsyPalette.primary.mainLight,
    "fontSize": theme.typography.smallFont.fontSize,
    "&:hover": {
      color: theme.lsyPalette.primary.mainDark
    }
  },
  dotIcon: {
    color: theme.lsyPalette.primary.mainLight,
    fontSize: theme.typography.fontSize,
    margin: `0 ${theme.spacing(0.75)}`
  },
  statusIcon: {
    marginLeft: "auto"
  },
  rightSpacing: {
    marginRight: theme.spacing(0.5)
  },
  checkbox: {
    "margin": `0 ${theme.spacing(0.25)}`,
    "& .MuiTypography-root": {
      fontSize: theme.typography.smallFont.fontSize
    },
    "& .MuiSvgIcon-root": { 
      fontSize: theme.typography.normalFont.fontSize
    }
  },
  placeholder: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "flexDirection": "column",
    "color": theme.lsyPalette.primary.mainDark,
    "height": "225px",
    "& div": {
      textAlign: "center"
    }
  },
  placeholderIcon: {
    fontSize: "48px",
    margin: theme.spacing(1),
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: theme.typography.normalFont.fontSize,
    color: theme.lsyPalette.primary.mainDark
  },
  arrowDownIcon: {
    transition: "transform 0.5s",
    color: theme.lsyPalette.primary.mainDark
  },
  arrowUpIcon: {
    transform: "rotate(-180deg)",
    transition: "transform 0.5s"
  },
  iconButton: {
    padding: 0,
    margin: `auto ${theme.spacing(0.5)}`
  },
  routeIcon: {
    margin: `0 ${theme.spacing(1)}`,
    display: "flex",
    alignItems: "center",
    color: "black"
  },
  titleWorkSessions: {
    display: "flex",
    alignItems: "center"
  },
  pagination: {
    "width": "fit-content",
    "margin": `0 ${theme.spacing(1)}`,
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "right",
    "marginLeft": "auto",
    "& button": {
      "fontSize": theme.typography.xsFontSize,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.typography.xsFontSize,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    "& .MuiToolbar-root": {
      padding: 0
    },
    "& .MuiTablePagination-displayedRows": {
      fontSize: theme.typography.smallFont.fontSize
    },
    "& .MuiInputBase-root": {
      fontSize: theme.typography.smallFont.fontSize
    },
    "& .MuiTablePagination-selectLabel": {
      display: "none"
    }
  },
  clickable: {
    cursor: "pointer"
  }
});
export default userNotificationStyle;