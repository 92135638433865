import { Fragment, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { generateGoolgeMapsUrlPoint, lsyRouter } from "_helpers";
import { useTranslation } from "react-i18next";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import moment from "moment";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

//components
import RegularButton from "_components/Button/RegularButton";

//icons
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/requestCardStyle.js";

const useStyles = makeStyles()(styles);

const REQUEST_STATUS = Object.freeze({
  PENDING: 0,
  APPROVED: 1,
  DENIED: 2
});

function RequestCard(props) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const { data, selected, drawerOpen, isMobile, shouldScroll, setSelected } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const cardRef = useRef();
  const statusMap = {
    0: t("label.pending"),
    1: t("label.approved"),
    2: t("label.denied")
  };

  useEffect(() => {
    if (selected) {
      if (isMobile && drawerOpen && shouldScroll.current) {
        shouldScroll.current = false;
        setTimeout(() => {
          cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }, 500);
      } else {
        cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [selected, drawerOpen, isMobile, shouldScroll]);

  const getUrgencyStatus = () => {
    let count, difference, endDate, startDate;
    startDate = moment(data.created_at);

    if (data.status === 0) {
      endDate = moment();
    } else {
      endDate = moment(data.updated_at);
    }

    difference = endDate.diff(startDate, "minutes", true);

    if (difference <= 15) { // less than 15 minutes
      count = 0;
    } else if (difference > 15 && difference <= 60) { // 15 minutes to 1 hour
      count = 1;
    } else if (difference > 60 && difference <= 300) { // 1 to 5 hours
      count = 2;
    } else { // more than 5 hours
      count = 3;
    }

    return <div className={classes.urgencyDiv}>
      <Tooltip classes={{ tooltip: classes.tooltip }} title={t(`label.urgencyRange${count}`)}>
        <div className={classes.tooltipDiv}>
          {[...Array(count).keys()].map((index) => {
            return <FiberManualRecordIcon key={index} className={cx(classes.dotIcon, {
              [classes.low]: count === 1,
              [classes.medium]: count === 2,
              [classes.high]: count === 3,
              [classes.iconPadding]: index === 0
            })} />;
          })}
        </div>
      </Tooltip>
    </div>;
  };

  const getDeviceName = (device) => {
    if (!device || !device.platform) {
      return ""; // Return an empty string if device or platform is undefined
    }

    const { platform } = device;
    return `${platform.name} ${platform.model} ${platform.version}`;
  };

  const linkField = (label, route, content) => {
    return <p className={classes.field}>
      {`${t(label)}: `}
      <span className={classes.actionLink} onClick={() => props.history.push(route)}>
        {content}
      </span>
    </p>;
  };

  const field = (label, content) => {
    return <p className={classes.field}>
      {`${t(label)}: `}
      <span className={classes.value}>
        {content}
      </span>
    </p>;
  };

  const openGoogleMaps = () => {
    window.open(generateGoolgeMapsUrlPoint(data.location), "_blank");
  };

  const handleSelect = () => {
    setSelected(data.id);
  };

  return (
    <Card ref={cardRef} className={cx(classes.root, { [classes.selected]: selected, [classes.disabled]: data.status !== 0 })} variant="outlined" onClick={handleSelect}>
      <CardContent className={classes.cardContent}>
        <div className={classes.titleDiv}>
          <p className={classes.title + " " + classes.actionLink} onClick={() => props.history.push(lsyRouter("lock", data.lock.id))}>{data.lock.name}</p>
          {data.location ?
            <Tooltip classes={{ tooltip: classes.tooltip }} title={t("label.showLocation")}>
              <IconButton size="small" aria-label="show location" onClick={openGoogleMaps}>
                <GpsFixedIcon className={classes.locationIcon} />
              </IconButton>
            </Tooltip> : null}
        </div>
        {linkField("label.requestedBy",
          lsyRouter("user", data.requestor_membership_id),
          `${data?.requestor?.first_name || ""} ${data?.requestor?.last_name || ""}`)}
        {field("label.status", statusMap[data.status])}
        {field("label.createdAt", moment(data.created_at).format("lll"))}
        {data.status !== REQUEST_STATUS.PENDING &&
          field("label.updatedAt", moment(data.updated_at).format("lll"))}
        {data.site?.name && ability.can("read", "lock_collections") &&
          linkField("widgetField.site", lsyRouter("site", data.site.id), data.site.name)}
        {field("label.fromDevice", getDeviceName(data.device))}
        {field("label.message", data.message)}
        {data.status && ability.can("update", "access_requests") > 0 ?
          linkField(data.status === 1 ? "label.approvedBy" : "label.deniedBy",
            lsyRouter("user", data.approver_membership_id),
            `${data?.approver?.first_name || ""} ${data?.approver?.first_name || ""}`
          ) :
          null}
      </CardContent>
      <div className={classes.actionDiv}>
        {getUrgencyStatus()}
        {data.status === 0 ? <Fragment>
          <RegularButton onClick={() => props.setDenyModal({ open: true, key: Math.random(), lock: data.lock.name, id: data.id })}>{t("actions.deny")}</RegularButton>
          <RegularButton onClick={() => props.setWizard({ open: true, key: Math.random(), lock: data.lock.name, id: data.id, siteName: data.site?.name })}>{t("actions.approve")}</RegularButton>
        </Fragment> : null}
      </div>
    </Card >
  );
}

RequestCard.propTypes = {
  history: PropTypes.object,
  data: PropTypes.object,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  setDenyModal: PropTypes.func,
  setWizard: PropTypes.func,
  drawerOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  shouldScroll: PropTypes.object
};

export default RequestCard;
