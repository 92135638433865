const lockNoteCardStyle = (theme) => ({
  tableItem: {
    "display": "flex",
    "margin": `${theme.spacing(.25)} 0`,
    "borderRadius": theme.shape.borderRadius,
    "padding": theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.lsyPalette.primary.light
    }
  },
  highlightedTableItem: {
    backgroundColor: theme.lsyPalette.primary.light
  },
  noteImageContainer: {
    maxWidth: "50px",
    maxHeight: "50px"
  },
  noteImage: {
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
    width: "auto",
    maxWidth: "inherit"
  },
  icon: {
    width: "1.75rem",
    height: "1.75rem"
  },
  tableItemText: {
    display: "flex",
    flexDirection: "column",
    margin: `0 ${theme.spacing(2)}`
  },
  tableItemTime: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.chipFontSize,
    marginBottom: theme.spacing(0.5)
  },
  tableItemDesc: {
    color: theme.palette.secondary.main,
    fontSize: theme.lsyPalette.subtitle3,
    marginBottom: theme.spacing(0.5)
  },
  shortCard: {
    width: "200px",
    height: "270px"
  },
  placeholder: {
    width: "200px",
    height: "200px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  placeholderMessage: {
    textAlign: "center"
  }
});

export default lockNoteCardStyle;