export const notificationGroups = Object.freeze([
  {
    name: "userNotifications.name.accessRequest",
    title: "userNotifications.title.accessRequest",
    type: "access_request",
    api_type: 4,
    api_label: "lock",
    description: "userNotifications.description.accessRequest",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.locks",
    type: "locks",
    description: "None",
    data: [
      {
        name: "userNotifications.name.openLock",
        title: "userNotifications.title.openLock",
        type: "open_lock",
        api_type: 8,
        api_label: "lock",
        description: "userNotifications.description.openLock",
        hasSelectAll: true,
        typeAhead: true
      },
      {
        name: "userNotifications.name.lockGroups", 
        title: "userNotifications.title.lockGroups", 
        type: "lock_group",
        api_type: 8,
        api_label: "lock_group",
        description: "userNotifications.description.lockGroups",
        hasSelectAll: false,
        typeAhead: true
      }
    ]
  }, 
  {
    name: "userNotifications.name.latchSensor",
    title: "userNotifications.title.latchSensor",
    type: "latch_sensor",
    api_type: 1,
    api_label: "lock",
    description: "userNotifications.description.latchSensor",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.doorSensor",
    title: "userNotifications.title.doorSensor",
    type: "door_sensor",
    api_type: 2,
    api_label: "lock",
    description: "userNotifications.description.doorSensor",
    hasSelectAll: true,
    typeAhead: true
  },
  {
    name: "userNotifications.name.lowBattery",
    title: "userNotifications.title.lowBattery",
    type: "low_battery",
    api_type: 3,
    api_label: "lock",
    description: "userNotifications.description.lowBattery",
    hasSelectAll: true,
    typeAhead: true
  }, 
  {
    name: "userNotifications.name.workSessions",
    type: "work_sessions",
    description: "None",
    data: [
      {
        name: "userNotifications.name.closeConfirmation",
        title: "userNotifications.title.closeConfirmation",
        type: "work_session_without_close_confirmation",
        api_type: 5,
        api_label: "user",
        description: "userNotifications.description.closeConfirmation",
        hasSelectAll: true,
        typeAhead: true
      }, 
      {
        name: "userNotifications.name.timeExceeded",
        title: "userNotifications.title.timeExceeded",
        type: "work_session_time_exceeded",
        api_type: 6,
        api_label: "user",
        description: "userNotifications.description.timeExceeded",
        hasSelectAll: true,
        typeAhead: true
      }
    ]
  }, 
  {
    name: "userNotifications.name.lockNote",
    title: "userNotifications.title.lockNote",
    type: "lock_note",
    api_type: 7,
    api_label: "lock",
    description: "userNotifications.description.lockNote",
    hasSelectAll: true,
    typeAhead: true
  },
  {
    name: "userNotifications.name.lockReplacement",
    title: "userNotifications.title.lockReplacement",
    type: "lock_replacement",
    api_type: [11, 12, 13],
    api_label: "lock",
    description: "userNotifications.description.lockReplacement",
    hasSelectAll: true,
    typeAhead: true
  }
]);