const accessWidgetStyle = theme => ({
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "500px"
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)"
  },
  topBar: {
    marginTop: "5px",
    display: "flex",
    alignItems: "center"
  },
  name: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  table: {
    width: "100%",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    marginTop: "10px"
  },
  field: {
    color: theme.lsyPalette.primary.mainLight
  },
  divider: {
    borderColor: theme.lsyPalette.primary.mainLight
  },
  chip: {
    marginRight: "3px"
  },
  chips: {
    marginTop: "5px",
    marginBottom: "5px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  iconButton: {
    marginRight: "5px"
  },
  value: {
    width: "70%"
  },
  toggleButton: {
    height: "25px",
    transition: "color .2s linear,background-color .3s linear",
    borderRadius: "15px",
    marginRight: "5px"
  },
  buttonInactive: {
    backgroundColor: "none",
    border: "none"
  },
  buttonActive: {
    "&.MuiToggleButton-root": {
      "backgroundColor": theme.lsyPalette.stdRed,
      "color": theme.lsyPalette.textContrast,
      "&:hover": {
        backgroundColor: theme.lsyPalette.stdRed
      }
    }
  },
  itemGrid: {
    width: "100%"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px"
  },
  marginTop: {
    marginTop: theme.spacing(1)
  }
});
export default accessWidgetStyle;
