import authClient from "../_helpers/axios/gw.client";
import Cookies from "universal-cookie";
import { clearStorageExcept } from "_helpers";
import { config } from "_configs/server-config";
import { setTitle } from "_helpers/page-title";
import { history, isAuthedDomain } from "_helpers";
import { tokenExpKey, currentMembershipCookieKey } from "../_helpers";

const versionArray = config.swVersion.split(".");

let twsVersionArray;
if (versionArray.length > 2) {
  twsVersionArray = `${versionArray[0]}.${versionArray[1]}`;
} else {
  twsVersionArray = versionArray;
}

export const twsVersion = twsVersionArray;

const cookies = new Cookies();

export const identityUrl = "identity";
export const lockdepotUrl = "lockdepot";
export const pushServiceUrl = "push_service";
export const twsWebUrl = `${config.s4SSOGwUrl}/public`;

export async function clearState() {
  clearStorageExcept(["adminPreferences", "language"]);
  sessionStorage.clear();

  cookies.remove(tokenExpKey, { path: "/", domain: `.${config.domain}` });
  // LEGACY UI cookie
  cookies.remove(currentMembershipCookieKey, { path: "/", domain: `.${config.domain}` });

  // only destroy if this is not our domain, as someone else may be logged in
  if (isAuthedDomain()) {
    await cookies.remove("twsAuthSD", { path: "/", domain: `.${config.domain}` });
  }
  // this has been moved to a SECURE cookie, thus cannot be removed by the browser anymore
  //await cookies.remove(tokenCookieKey, { path: "/", domain: ".sera4.com" });
}

export async function logout(softLogout = false) {
  const sessionId = localStorage.getItem("sessionId");
  let client;

  if (sessionId) {
    setTitle();

    // remove user from local storage to finalize logout
    const requestOptions = {
      method: "post",
      url: `sessions/${sessionId}/destroy`
    };

    if (!softLogout) {
      try {
        client = await authClient(requestOptions);
      } catch (e) {
        // do nothing here - 401's may happen if the token is already expired
      }
    }

    clearState();
  }

  return client;
}

export function v2Handler(response) {
  var x_pagination = JSON.parse(response.headers["x-pagination"]);
  var r = {
    data: response.data,
    meta: {
      pagination: x_pagination
    }
  };
  return r;
}

export function handleResponse(response, options = { refresh: false }) {
  options = {
    refresh: true,
    ...options
  };

  if (response.status === 401) {
    // auto logout if 401 response returned from api
    logout();
    if (options.refresh)
      history.replace("/");
  }
  return response.data;
}

const lsyServerBaseUrl = (serverAlias) => {
  return `${config.tenantProtocol}${serverAlias}.${config.domain}${config.tenantPort}`;
};

export function lsyServerV2Url(serverAlias) {
  return `${lsyServerBaseUrl(serverAlias)}/v2`;
}

export function lsyServerUrl(serverAlias) {
  return `${lsyServerBaseUrl(serverAlias)}/v3/public`;
}

export function lsyShadowUrl(tenantId) {
  return `${config.s4SSOGwUrl}/lockstasy/${tenantId}/public`;
}
