const accessRequestsStyle = (theme) => ({
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    paddingTop: "25px"
  },
  icon: {
    color: theme.palette.text.primary
  },
  titleDiv: {
    display: "flex"
  },
  title: {
    color: theme.lsyPalette.text,
    fontSize: theme.lsyPalette.body2,
    marginTop: "8px",
    marginBottom: "8px",
    display: "inline-block",
    flexGrow: 1
  },
  drawerTitle: {
    marginLeft: theme.spacing(2),
    color: theme.lsyPalette.textContrast,
    flex: 1
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "0"
  },
  map: {
    filter: "grayscale(1)",
    width: "100%",
    padding: "0px !important", // <GridItem> also has !important in it's style
    border: `1px solid ${theme.lsyPalette.primary.mainLight}`
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  controls: {
    border: `1px solid ${theme.lsyPalette.primary.mainLight}`,
    backgroundColor: theme.lsyPalette.secondary.main,
    width: "100%"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  loader: {
    display: "inline-block",
    paddingLeft: "10px",
    flexGrow: 1,
    marginTop: "8px"
  },
  cardDiv: {
    overflowY: "auto",
    marginTop: "15px",
    height: "745px"
  },
  borderDiv: {
    height: "100%"
  },
  appBar: {
    position: "relative",
    backgroundColor: theme.lsyPalette.primary.main,
    fontSize: theme.lsyPalette.title2
  },
  container2: {
    minHeight: "100vh",
    paddingTop: "25px"
  },
  iconContrast: {
    color: theme.lsyPalette.secondary.main
  },
  skeletonTitle: {
    paddingBottom: "5px", 
    marginBottom: "10px"
  },
  skeletonContent: {
    marginLeft: "5px", marginTop: "5px"
  },
  drawerButton:{
    backgroundColor: theme.lsyPalette.secondary.main,
    marginBottom: "25px",
    marginRight: "10px"
  },
  filterModal: {
    [theme.breakpoints.down("lg")]: {
      width: "100%"
    },
    width: "400px"
  },
  denyModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "600px"
  }
});
export default accessRequestsStyle;
