import { useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEqual, isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import { useCustomCompareEffect } from "use-custom-compare";

import { NOTE_TYPE } from "_constants/resourceNote.constants";
import {
  formatDate,
  isNullOrUndefined,
  compactObj
} from "_helpers";
import { genericReducer } from "_reducers/general.reducer";
import { userService, lockService } from "_services/lockstasy";

import {
  Chip,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography
} from "@mui/material";

import DateTimeRange from "_components/Date/DateTimeRange";
import ErrorBoundary from "_components/ErrorBoundary";
import CustomAsyncSearchForm from "_components/Form/CustomAsyncSearchForm";
import LsyFrame from "_components/Lockstasy/LsyFrame";
import CustomModal from "_components/Modal/CustomModal";
import LockNoteDetailsWidget from "_containers/Widgets/LockNoteDetailsWidget";
import LockNotesTable from "_containers/Widgets/LockNotesTable";

import { Person } from "@mui/icons-material";

import LockIcons from "assets/teleporte/LockIcons";

import styles from "assets/jss/views/lockstasy/lockNotesStyle";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

const defaultInitialState = Object.freeze({
  start_date: "",
  end_date: "",
  noteType: 1,
  resources: [],
  author: "",
  systemGenerated: ""
});

function LockNotes(props) {
  const { location, history, baseUrl, org } = props;
  const { search } = location;
  const theme = useTheme();

  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const language = useSelector((state) => state.locale.language);
  moment.locale(language);

  const [stateForSearch, setStateForSearch] = useReducer(genericReducer,
    {
      groups: [],
      locks: [],
      sites: [],
      users: []
    }
  );

  const addLocks = useCallback((locks) => {
    if (isEmpty(locks)) {
      return;
    }
    
    const newLocks = locks.filter(lock => !stateForSearch.locks.some(l => l.id === lock.id));

    setStateForSearch({ locks: [...stateForSearch.locks, ...newLocks] });
  }, [stateForSearch.locks]);

  const addUsers = useCallback((users) => {
    if (isEmpty(users)) {
      return;
    }

    const newUsers = users.filter(user => !stateForSearch.users.some(u => u.id === user.id));

    setStateForSearch({ users: [...stateForSearch.users, ...newUsers] });
  }, [stateForSearch.users]);

  const setGroups = useCallback(newGroups => setStateForSearch({ groups: [...stateForSearch.groups, ...newGroups] }), [stateForSearch.groups]);
  const setSites = useCallback(newSites => setStateForSearch({ sites: [...stateForSearch.sites, ...newSites] }), [stateForSearch.sites]);

  const getInitialState = () => {
    const filterVars = {
      ...defaultInitialState,
      ...search ?
        queryString.parse(search.replace("lockId", "lock_ids").replace("membershipId", "membership_id"), { arrayFormat: "separator", arrayFormatSeparator: "," }) : {}
    };
    const filterOptions = compactObj({ ...filterVars });
    const filterVarsKeys = Object.keys(filterOptions);
    const formattedFilterOp = {};

    filterVarsKeys.forEach(field => {
      if (Array.isArray(filterOptions[field])) {
        formattedFilterOp[field] = filterOptions[field]?.map(item => {
          return { id: item };
        });
      } else if (field === "lock_ids" && filterOptions.noteType == NOTE_TYPE.lock) {
        const lockId = filterOptions[field];
        formattedFilterOp.resources = [{ id: lockId }];
      } else if (field === "membership_id") {
        const membershipId = filterOptions[field];
        formattedFilterOp.author = { id: membershipId };
      } else {
        formattedFilterOp[field] = filterOptions[field];
      }
    });

    if (filterOptions.start_date) {
      formattedFilterOp.start_date = moment(filterOptions.start_date, "YYYY/MM/DD");
      formattedFilterOp.end_date = moment(filterOptions.end_date, "YYYY/MM/DD");
    }

    return { ...formattedFilterOp };
  };

  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      currentPage: 1,
      totalData: 0,
      data: [],
      noteImages: [],
      filterVariables: initialState,
      loading: true,
      locksSearchOpen: false,
      userSearchOpen: false,
      author: initialState.author || "",
      recipientUsers: initialState.noteType == NOTE_TYPE.user ? initialState.resources : [],
      recipientLocks: initialState.noteType == NOTE_TYPE.lock ? initialState.resources : [],
      noteType: initialState.noteType
    }
  );

  const setLoading = loading => setState({loading});
  const setRecipientLocks = recipientLocks => setState({ recipientLocks });
  const setAuthor = author => setState({ author });
  const setLocksSearchOpen = open => setState({ locksSearchOpen: open });
  const setUserSearchOpen = open => setState({ userSearchOpen: open });
  const openlocksSearch = () => setLocksSearchOpen(true);
  const openUserSearch = () => setUserSearchOpen(true);
  const addImages = useCallback((images) => {
    if (isEmpty(images)) {
      return;
    }

    const newImages = images.filter(img => !state.noteImages.some(ni => ni.id === img.id));

    setState({ noteImages: [...state.noteImages, ...newImages] });
  }, [state.noteImages]);

  const [noteState, setNoteState] = useReducer(genericReducer,
    {
      data: {},
      photo: ""
    }
  );

  const setFilterVariables = newFilters => setState({ filterVariables: {...state.filterVariables, ...newFilters}, currentPage: 1 });
  const setDateRange = dateRange => setFilterVariables({ start_date: dateRange[0], end_date: dateRange[1] });
  const setSystemGenerated = systemGenerated => setFilterVariables({ systemGenerated });
  const setCurrentPage = (page) => setState({ currentPage: page });

  const systemGeneratedOptions = [
    { name: t("label.generatedBySystem"), value: true },
    { name: t("label.generatedByUser"), value: false },
    { name: t("label.generatedByAll"), value: " "}
  ];
  
  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({ currentPage: 1, rowsPerPage: 10, search: inputValue });
      const formattedResult = result.data.map(v => v ? { id: v.membership_id, name: `${v.first_name} ${v.last_name} (${v.email})`} : null);
      addUsers(formattedResult);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch users with given input", e);
    }
  }, [addUsers]);

  const fetchLocksWithInput = useCallback(async (inputValue) => {
    try {
      const result = await lockService.fetchLocks({ search: inputValue });
      const formattedResult = result.data.map(v => v ? { name: v.full_identifier, id: v.id } : null);
      addLocks(formattedResult);
      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
      return [];
    }
  }, [addLocks]);
  
  const updateUrlQuery = useCallback((newState) => {
    const newFilterVariables = {
      ...newState,
      ... newState.start_date && { start_date: formatDate(newState.start_date, "YYYY-MM-DD") },
      ... newState.end_date && { end_date: formatDate(newState.end_date, "YYYY-MM-DD") }
    };

    if (!isEqual(newFilterVariables, state.filterVariables)) {
      const filterOptions = compactObj({ ...newFilterVariables });
      const filterOptionsKeys = Object.keys(filterOptions);
      const formattedFilterOp = {};

      filterOptionsKeys.forEach(field => {
        if (Array.isArray(filterOptions[field])) {
          if (field === "resources") {
            formattedFilterOp["lock_ids"] = filterOptions[field].map(item => item.id);
          }
        } else if (field === "author") {
          formattedFilterOp["membership_id"] = filterOptions[field].id;
        } else {
          formattedFilterOp[field] = filterOptions[field];
        }
      });

      history.replace(`/lock_notes?${queryString.stringify({...formattedFilterOp}, { arrayFormat: "separator", arrayFormatSeparator: ",", encode: false })}`);
    }
  }, [history, state.filterVariables]);
  
  useCustomCompareEffect(() => {
    updateUrlQuery(state.filterVariables);
  }, [state.filterVariables], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const resetFilter = (filterVar) => {
    let newState = {
      ...state.filterVariables,
      [filterVar]: defaultInitialState[filterVar],
      ...filterVar === "noteType" && { resources: defaultInitialState.resources },
      ...filterVar === "author" && { author: defaultInitialState.author }
    };
    setState({
      filterVariables: newState,
      currentPage: 1,
      author: filterVar === "author" ? defaultInitialState.author : state.author,
      recipientUsers: filterVar === "noteType" ? defaultInitialState.resources : state.recipientUsers,
      recipientLocks: filterVar === "noteType" ? defaultInitialState.resources : state.recipientLocks,
      noteType: filterVar === "noteType" ? defaultInitialState[filterVar] : state.noteType
    });
    updateUrlQuery({...newState, start_date: state.filterVariables.start_date, end_date: state.filterVariables.end_date});
  };

  const onSubmit = (data) => {
    if (!isEqual(data, state.filterVariables)) {
      setState({
        filterVariables: { ...state.filterVariables, ...data },
        currentPage: 1,
        locksSearchOpen: false,
        userSearchOpen: false
      });
    }
  };

  const renderDateTimeRangeForm = () => {
    return <DateTimeRange 
      startDate={state.filterVariables.start_date}
      setDateRange={setDateRange}
      endDate={state.filterVariables.end_date}
      showTime={false}
      label={" "}
      format="YYYY/MM/DD"
      showQuickFilter
      inputProps={{variant: "standard", disableUnderline: true, style: {fontSize: theme.typography.chipFontSize, margin: 0, marginTop: "auto", marginBottom: "auto", fontWeight: "300"}}}
    />;
  };

  const renderLockNoteWidget = useCallback(() => {
    return <LockNoteDetailsWidget 
      data={noteState.data}
      images={state.noteImages}
      isLoading={state.loading}
      history={history}
      org={org}
      baseUrl={baseUrl}
      groups={stateForSearch.groups}
      sites={stateForSearch.sites}
      setGroups={setGroups}
      setSites={setSites}
    />;
  }, [baseUrl, history, noteState, org, state.loading, stateForSearch, state.noteImages, setGroups, setSites]);

  const handleSearchRecipientSubmit = () => {
    const data = {
      resources: state.noteType == NOTE_TYPE.user ? state.recipientUsers : state.recipientLocks,
      noteType: state.noteType
    };

    onSubmit(data);
  };
  const handleSearchRecipientCancel = () => resetFilter("noteType");
  const handleSearchRecipientClose = () => {
    if (isEmpty(state.recipientUsers) && isEmpty(state.recipientLocks) && !isEmpty(state.filterVariables.resources)) {
      handleSearchRecipientCancel();
    }
  };

  const handleSearchAuthorSubmit = () => {
    onSubmit({ author: state.author });
  };
  const handleSearchAuthorCancel = () => resetFilter("author");

  const getAuthor = useCallback(() => {
    const author = stateForSearch.users.find(u => u.id === state.author.id);
    return author ? author : state.author;
  },[state.author, stateForSearch.users]);

  const getRecipientLocks = useCallback(() => {
    return state.recipientLocks?.map(l => {
      const lock = stateForSearch.locks.find(s => s.id == l.id);
      return lock ? lock : l;
    });
  }, [state.recipientLocks, stateForSearch.locks]);

  const getSearchUser = useCallback(() => {
    return <CustomAsyncSearchForm 
      setSelectorValues={setAuthor} 
      placeholder={t("label.searchUsers")} 
      fetchWithInput={fetchUsersWithInput} 
      selectorValues={[getAuthor()]}
      isMulti={false}
      closeMenuOnSelect={true}
    />;
  }, [ fetchUsersWithInput, getAuthor, t ]);

  const handleGeneratedBy = event => {
    const value = event.target.value;
    setSystemGenerated(value);
  };

  return <LsyFrame>
    <Grid container justifyContent="center">
      <Grid item xs={12} md={6.6}>
        <Typography variant="body1"><b>{t("features.lockNotes")}</b></Typography>
        <div className={classes.header} data-testid="chips">
          <Chip
            className={classes.chip}
            data-testid="date-picker"
            label={renderDateTimeRangeForm()}
          />
          <Chip
            label={
              <div className={classes.header}>
                <LockIcons type="AP3" className={classes.chipIcon}/>
                <div className={classes.chipText}>
                  {!isEmpty(state.filterVariables.resources) ?
                      `${t("features.locks")}: ${state.filterVariables.resources.length}` : 
                    t("label.searchLocks")}
                </div>
              </div>}
            onClick={openlocksSearch}
            className={classes.chip}
          />
          <Chip
            label={
              <div className={classes.header}>
                <Person className={classes.chipIcon}/>
                <div className={classes.chipText}>
                  <div className={classes.searchContainer}>
                    {isEmpty(state.filterVariables.author) ?
                      t("label.searchUsers") :
                      getAuthor().name
                    }
                  </div>
                </div>
              </div>}
            onClick={openUserSearch}
            className={classes.chip}
          />
          <Chip
            label={
              <div className={classes.header}>
                <Select
                  value={isNullOrUndefined(state.filterVariables.systemGenerated) ? " " : state.filterVariables.systemGenerated}
                  className={classes.chipSelect}
                  onChange={handleGeneratedBy}
                  SelectDisplayProps={{ style: { paddingRight: 0 } }}
                >
                  {systemGeneratedOptions.map(option => <MenuItem key={`systemGenerated${option.value}`} value={option.value}>{option.name}</MenuItem>)}
                </Select>
              </div>}
            className={classes.chip}
          />
          {/* It will be done on second phase */}
          {/* <Chip
            label={
              <div className={classes.header}>
                <NoteAddOutlined className={classes.chipIcon}/>
                <div className={classes.chipText}>
                  <div className={classes.searchContainer}>
                    {t("button.newNote")}
                  </div>
                </div>
              </div>}
            onClick={() => {}}
            className={cx(classes.chip, classes.newNoteChip)}
          />  */}
        </div>
        <ErrorBoundary>
          <LockNotesTable
            filterVariables={state.filterVariables}
            currentPage={state.currentPage}
            setCurrentPage={setCurrentPage}
            setNoteState={setNoteState}
            clickedNoteId={`${noteState?.data?.id}`}
            setLoading={setLoading}
            locks={stateForSearch.locks}
            users={stateForSearch.users}
            images={state.noteImages}
            addLocks={addLocks}
            addUsers={addUsers}
            addImages={addImages}
          />
        </ErrorBoundary>
      </Grid>
      <Grid item xs={false} md={0.4} className={classes.displayGrid}>
        <Divider orientation="vertical" flexItem className={classes.divider}/>
      </Grid>
      <Grid item xs={12} md={5}>
        <ErrorBoundary>
          {renderLockNoteWidget()}
        </ErrorBoundary>
      </Grid>
    </Grid>

    <CustomModal
      open={state.locksSearchOpen}
      setOpen={setLocksSearchOpen}
      handleSubmit={handleSearchRecipientSubmit}
      handleCancel={handleSearchRecipientCancel}
      handleClose={handleSearchRecipientClose}
      type="custom"
      title={t("form.search")}
      confirm={t("button.apply")}
      cancel={t("button.reset")}
      manualClose
      description={
        <CustomAsyncSearchForm 
          setSelectorValues={setRecipientLocks} 
          placeholder={t("label.searchLocks")} 
          fetchWithInput={fetchLocksWithInput} 
          selectorValues={getRecipientLocks()}
        />
      }
      cancelButton
      confirmButton
      modalStyle={classes.modal}
      submit
    />
    <CustomModal
      open={state.userSearchOpen}
      setOpen={setUserSearchOpen}
      handleSubmit={handleSearchAuthorSubmit}
      handleCancel={handleSearchAuthorCancel}
      type="custom"
      title={t("label.searchUsers")}
      confirm={t("button.apply")}
      cancel={t("button.reset")}
      manualClose
      description={getSearchUser()}
      cancelButton
      confirmButton
      modalStyle={classes.modal}
      submit
    />
  </LsyFrame>;
}

LockNotes.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object,
  baseUrl: PropTypes.string
};

export default LockNotes;