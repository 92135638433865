/* This file compliments member.service and uses it for any of it's backend functions
  -- actions are related to dispatches and using redux, so if you need direct access
    to services, then use the memberService class instead
*/
import * as actionTypes from "./actionTypes";
import { memberService } from "_services";
import { currentMembershipCookieKey } from "_helpers";
import { adminConstants } from "_constants/admin.constants";
import { updateObject } from "../_helpers/utility";
import Cookies from "universal-cookie";
import get from "lodash/get";
import { config } from "process";
import { config as serverConfig } from "_configs/server-config";

const cookies = new Cookies();

/* these can be returned by multiple controllers, let all states interested know about it */
export const transposeMemberships = (memberships) => {
  return memberships.map(membership => {
    let server_alias = get(membership, "tenant.server_alias.name") || get(membership, "server.alias");
    let returnHash = {
      tenant_name: membership.tenant.name,
      tenant_id: membership.tenant.id,
      id: membership.id,
      status: membership.status,
      user_id: membership.tenant_user_id,
      features: membership.tenant.features,
      server_alias: server_alias,
      role_id: membership.role_id,
      authorized: membership.authorized
    };

    if (membership.s4_role) {
      returnHash["s4_role"] = membership.s4_role;
      if (membership.s4_role === adminConstants.SERA4TAL_ADMIN)
        delete returnHash["features"];
    }

    return returnHash;
  });
};

function setMemberships(memberships) {
  let tMemberships = transposeMemberships(memberships);
  localStorage.setItem("memberships", JSON.stringify(tMemberships));
  tMemberships.forEach((membership) => {
    if (membership.s4_role > 0) {
      localStorage.setItem("adminMembership", JSON.stringify(membership));
    }
  });

  return { type: actionTypes.MEMBERSHIPS_SET, memberships: tMemberships };
}

/* fetch the current users memberships */
function fetchMemberships() {
  function request() { return { type: actionTypes.MEMBERSHIPS_START }; }
  function failure(error) { return { type: actionTypes.MEMBERSHIPS_FAIL, error }; }

  return dispatch => {
    dispatch(request());
    memberService.fetchMyMemberships()
      .then(
        memberships => {
          dispatch(setMemberships(memberships.data));
        }
      )
      .catch(error => {
        dispatch(failure(error.toString()));
      });
  };
}

function storeCurrentMembership(membership) {
  sessionStorage.setItem("membershipId", membership.id);
  // required only for legacy LSY membership
  cookies.set(currentMembershipCookieKey, membership, { path: "/", domain: `.${serverConfig.domain}` });
  return { type: actionTypes.MEMBERSHIP_SET_CURRENT, currentMembership: membership };
}

function setSettingsCurrentMembership(settings) {
  return { type: actionTypes.MEMBERSHIP_SET_CURRENT_SETTINGS, settings: settings };
}

function removeCurrentMembership() {
  cookies.remove(currentMembershipCookieKey, { path: "/", domain: `.${serverConfig.domain}` });
  return { type: actionTypes.MEMBERSHIP_REMOVED_CURRENT };
}

function removeMembershipFromList(membership, memberships) {
  const newMembershipsList = memberships.filter(m => m.id !== membership.id);
  localStorage.setItem("memberships", JSON.stringify(newMembershipsList));
  newMembershipsList.forEach((membership) => {
    if (membership.s4_role > 0) {
      localStorage.setItem("adminMembership", JSON.stringify(membership));
    }
  });

  return { type: actionTypes.MEMBERSHIPS_SET, memberships: newMembershipsList };
}

function membershipUpdate(tenantId, data) {
  const localMemberships = localStorage.getItem("memberships");
  var membershipList = JSON.parse(localMemberships);
  let index;

  for (index = 0; index < membershipList.length; index++) {
    if (membershipList[index].tenant_id === tenantId) {
      membershipList[index] = updateObject(membershipList[index], data);
      break;
    }
  }

  localStorage.setItem("memberships", JSON.stringify(membershipList));
  return { type: actionTypes.MEMBERSHIPS_SET, memberships: membershipList };
}

export const memberActions = {
  setMemberships,
  fetchMemberships,
  storeCurrentMembership,
  setSettingsCurrentMembership,
  removeCurrentMembership,
  membershipUpdate,
  removeMembershipFromList
};
