import { useContext, useEffect, useReducer, useCallback, forwardRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCustomCompareEffect } from "use-custom-compare";
import { get, isEqual, uniq } from "lodash";
import reactStringReplace from "react-string-replace";
import moment from "moment";

import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { isBlank, lsyRouter } from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { notificationService, lockNoteService } from "_services/lockstasy";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

//components
import ErrorBoundary from "_components/ErrorBoundary";
import NotificationComment from "_components/Lockstasy/NotificationComment";
import Placeholder from "_components/Helper/Placeholder";
import LsyRouter from "_components/Navigation/LsyRouter";

//icons
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import BatteryAlert from "@mui/icons-material/BatteryAlert";
import LockIcons from "assets/teleporte/LockIcons";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import DoneIcon from "@mui/icons-material/Done";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/notificationCardStyle.js";

const useStyles = makeStyles()(styles);

const NotificationCard = forwardRef((props, ref) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const canReadWorkSessions = ability.can("read", "work_sessions");

  const { alert, history, fetchWidgetData, showDismissed } = props;
  const widgetState = props.state;
  const setWidgetState = props.setState;
  const criticality = alert.type.criticality;
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      resolved: alert.resolved,
      commentCount: alert.payload?.comments_count || 0,
      updatedBy: alert.updated_by?.name || "",
      timestamp: alert.timestamp
    }
  );
  const setResolved = (resolved) => setState({ resolved });
  const setUpdatedBy = (updatedBy) => setState({ updatedBy });
  const commentForm = useForm();
  const user = useSelector((state) => state.auth.user);

  useCustomCompareEffect(() => {
    setState({
      resolved: alert.resolved,
      commentCount: alert.payload?.comments_count || 0,
      updatedBy: alert.updated_by?.name || "",
      timestamp: alert.timestamp || alert.created_at || ""
    });
  }, [alert], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    if (!showDismissed && state.resolved) {
      if (widgetState.currentPage !== 1) {
        setWidgetState({ currentPage: 1 });
      } else {
        fetchWidgetData();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [state.resolved]);

  const getIcon = (type) => {
    switch (type) {
      case "notifications.type.lock.sensor.latch":
        return <LockIcons type={alert?.lock?.hardwareType || "AP3"} className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} pathClassName={classes.svgIconPath} height="24px" width="24px" />;
      case "notifications.type.lock.sensor.door":
        return <LockIcons type={alert?.lock?.hardwareType || "AP3"} className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} pathClassName={classes.svgIconPath} height="24px" width="24px" />;
      case "notifications.type.lock.battery.low":
        return <BatteryAlert className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.access_request":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.work_session.not_confirmed":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.work_session.wstl_exceeded":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.lock.note":
        return <LockIcons type={alert?.lock?.hardwareType || "AP3"} className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} pathClassName={classes.svgIconPath} height="24px" width="24px" />;
      case "notifications.type.user.custom":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.lock.custom":
        return <LockIcons type={alert?.lock?.hardwareType || "AP3"} className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} pathClassName={classes.svgIconPath} height="24px" width="24px" />;
      case "notifications.type.mfa.enabled":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.mfa.disabled":
        return <PersonIcon className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} />;
      case "notifications.type.lock_replacement":
      case "notifications.type.lock_replacement.finished":
      case "notifications.type.lock_replacement.failed":
        return <LockIcons type={alert?.lock?.hardwareType || "AP3"} className={cx(classes.icon, { [classes.criticalIcon]: criticality === 1, [classes.dismissedIcon]: state.resolved })} pathClassName={classes.svgIconPath} height="24px" width="24px" />;
    }
  };

  const titles = Object.freeze({
    "notifications.type.lock.sensor.latch": t("label.latchSensor"),
    "notifications.type.lock.sensor.door": t("label.doorSensor"),
    "notifications.type.lock.battery.low": t("widgetField.lowBattery"),
    "notifications.type.access_request": t("label.accessRequest"),
    "notifications.type.work_session.not_confirmed": t("label.workSession"),
    "notifications.type.work_session.wstl_exceeded": t("label.workSession"),
    "notifications.type.lock.note": t("label.lockNote"),
    "notifications.type.lock.custom": t("label.customLock"),
    "notifications.type.user.custom": t("label.customUser"),
    "notifications.type.mfa.enabled": t("label.mfaEnabled"),
    "notifications.type.mfa.disabled": t("label.mfaDisabled"),
    "notifications.type.lock_replacement": t("userNotifications.name.lockReplacement"),
    "notifications.type.lock_replacement.finished": t("userNotifications.name.lockReplacement"),
    "notifications.type.lock_replacement.failed": t("userNotifications.name.lockReplacement")
  });

  const formatAsLink = (text, page, id) => {
    return <LsyRouter className={classes.actionLink} page={page} id={id}>
      {text}
    </LsyRouter>;
  };

  const getUserAsLink = () => {
    return formatAsLink(alert.user.name, "user", alert.user.membership_id);
  };

  const getMfaMessage = (type) => {
    if (alert.updater?.name) {
      if (alert.updater.membership_id === alert.user.membership_id) {
        return t(`${type}Own`, { user: getUserAsLink() });
      }
      return t(`${type}WithUpdater`, { updater: formatAsLink(alert.updater.name, "user", alert.updater.membership_id), user: getUserAsLink() });
    } else {
      return t(`${type}`, { user: getUserAsLink() });
    }
  };

  const sanityHandlebarVariable = (alert, match) => {
    let value = get(alert, match.replace(/[{}]/g, ""), "");
    
    if (typeof value === "object")
      value = "";

    return value || "";
  };
  
  /* these custom workloads support variable substitution, in order to use it,
  *  simply use the resource name and identified to dereference it (ex: The person {{ user.name }} was escorted off the property)
  */
  const convertMessage = (alert) => {
    if (isBlank(alert.payload.message) || typeof alert.payload.message === "object")
      return alert;

    let newMessage = alert.payload.message;

    // try to find dereferencing statements like {{user.name}} or {{lock.id}} and use the alert
    //  payload to search for those references.  Right now we'll support only 'user' and 'lock'
    //  since we also know how to link to those resources nicely.
    const matches = uniq(newMessage.match(/{{(user|lock)\.\w+}}/g));

    // try to find statements like markdown style: [Link](https://www.link.com) 
    //  or regular links https://www.link.com and make them clickable
    const urlMatches = uniq(newMessage.match(/\[(.*?)\]\(.*?\)|((http:\/\/|https:\/\/)[^\s]*)/g));

    try {
      matches.forEach(element => {
        // search {{user.lock}} for the resource [user, lock, ..]
        switch (element.replace(/[{}]/g, "").match(/(\w+)\./)[1]) {
          case "user":
            newMessage = reactStringReplace(newMessage, /({{user.[\w.]+}})/gi, (match, i) => (
              <span key={match + i} className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{sanityHandlebarVariable(alert, match)}</span>
            ));
            break;
          case "lock":
            newMessage = reactStringReplace(newMessage, /({{lock.[\w.]+}})/gi, (match, i) => (
              <span key={i} className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{sanityHandlebarVariable(alert, match)}</span>
            ));
            break;
        }
      });

      urlMatches.forEach(element => {
        // See if our url is in markdown form (starting with '[') or not
        switch (element.charAt(0) === "[") {
          case true:
            newMessage = reactStringReplace(newMessage, /\[(.*?)\]\(.*?\)/gi, (match, i) => (
              <span key={i} className={classes.actionLink} onClick={() => window.open(element.match(/\((.*?)\)/)[1], "_blank")}>{match}</span>
            ));
            break;
          case false:
            newMessage = reactStringReplace(newMessage, element, (match, i) => (
              <span key={i} className={classes.actionLink} onClick={() => window.open(match, "_blank")}>{match}</span>
            ));
            break;
          default:
            break;
        }
      });
    } catch (e) {
      // we absolutely CANNOT fail to show the screen because of an error here.
      // we'd rather just show the original raw message than show an error.
      console.debug(`Could not parse message ${newMessage}: `, e);
    }
    
    alert.payload.message = newMessage;
    return alert;
  };

  const getWorkSessionMessage = (type, params) => {
    const openWorkSession = () => {
      const wsState = {
        user: `${alert.user.name} (${alert.user.email})`,
        workSessionId: alert.payload.work_session_id
      };

      history.pushWithState(lsyRouter("user_work_sessions", alert.user.membership_id), wsState);
    };
    params = {
      workSession: canReadWorkSessions ? 
        <span className={classes.actionLink} onClick={openWorkSession}>{t("label.workSession")}</span> :
        <span>{t("label.workSession")}</span>,
      user: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>
    };

    return t(type, params);
  };

  const getMessage = (type) => {
    var params;
    switch (type) {
      case "notifications.type.lock.sensor.latch": {
        params = {
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{alert.lock.name}</span>
        };

        if (alert.user) {
          type += "WithUser";
          params.user = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>;
        }

        if (alert?.lock?.site?.name) {
          params.site = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("site", alert.lock.site.id))}>{` [${alert.lock.site.name}]`}</span>;
        }

        return t(type, params);
      }
      case "notifications.type.lock.sensor.door": {
        params = {
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{alert.lock.name}</span>
        };

        if (alert.user) {
          type += "WithUser";
          params.user = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>;
        }

        if (alert?.lock?.site?.name) {
          params.site = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("site", alert.lock.site.id))}>{` [${alert.lock.site.name}]`}</span>;
        }
        return t(type, params);
      }
      case "notifications.type.lock.battery.low": {
        params = {
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{alert.lock.name}</span>
        };

        if (alert?.lock?.site?.name) {
          params.site = <span className={classes.actionLink} onClick={() => history.push("site", alert.lock.site.id)}>{` [${alert.lock.site.name}]`}</span>;
        }
        return t(type, params);
      }
      case "notifications.type.access_request": {
        params = {
          request: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("access_request", alert.payload.access_request_id))}>{t("label.accessRequest")}</span>,
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{alert.lock.name}</span>,
          user: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>
        };

        if (alert?.lock?.site?.name) {
          params.site = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("site", alert.lock.site.id))}>{` [${alert.lock.site.name}]`}</span>;
        }

        return t(type, params);
      }
      case "notifications.type.work_session.wstl_exceeded":
      case "notifications.type.work_session.not_confirmed":
        return getWorkSessionMessage(type, params);
      case "notifications.type.lock.note": {
        let message = alert.payload.note_message || "";
        if (message.length > 25) {
          message = message.substring(0, 500) + "...";
        } else if (message === "-") {
          message = "";
        }

        params = {
          note: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock_notes"))}>{message}</span>,
          user: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>,
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{alert.lock.name}</span>
        };

        if (alert?.lock?.site?.name) {
          params.site = <span className={classes.actionLink} onClick={() => history.push(lsyRouter("site", alert.lock.site.id))}>{` [${alert.lock.site.name}]`}</span>;
        }

        return t(type, params);
      }
      case "notifications.type.user.custom":
      case "notifications.type.lock.custom": {
        let convertedAlert = convertMessage(alert);

        return <span>{convertedAlert.payload.message}</span>;
      }
      case "notifications.type.mfa.enabled":
        return getMfaMessage(type);
      case "notifications.type.mfa.disabled":
        return getMfaMessage(type);
      case "notifications.type.lock_replacement":
      case "notifications.type.lock_replacement.finished":
      case "notifications.type.lock_replacement.failed":
        params = {
          user: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("user", alert.user.membership_id))}>{alert.user.name}</span>,
          lock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock.id))}>{getFormattedLockName(alert.lock)}</span>,
          replacementLock: <span className={classes.actionLink} onClick={() => history.push(lsyRouter("lock", alert.lock_replacement.id))}>{getFormattedLockName(alert.lock_replacement)}</span>
        };

        return type === "notifications.type.lock_replacement" ? t(`${type}.started`, params) : t(type, params);
    }
  };

  const submitComment = useCallback(async (data) => {
    if (!data?.comment) {
      return;
    }

    try {
      var options = {
        notificationId: alert.id,
        data: data
      };
      await notificationService.createComment(options);
    } catch (e) {
      console.warn(e);
      const status = e.response.status;
      if (status === 403) {
        dispatch(alertActions.send(t("error.permissionDenied"), "error"));
      } else {
        dispatch(alertActions.send(t("error.submitComment"), "error"));
      }
    }
  }, [alert.id, dispatch, t]);

  const flipResolvedStatus = async () => {
    try {
      var options = {
        notificationId: alert.id,
        data: {
          resolved: !state.resolved + ""
        }
      };
      await notificationService.updateNotification(options);
      setResolved(!state.resolved);
      const userName = user.first_name + " " + user.last_name;

      if (userName !== state.updatedBy) {
        setUpdatedBy(userName);
      }

    } catch (e) {
      console.warn(e);
      const status = e.response.status;
      if (status === 403) {
        dispatch(alertActions.send(t("error.permissionDenied"), "error"));
      } else {
        !state.resolved ? dispatch(alertActions.send(t("error.dismissNotification"), "error")) : dispatch(alertActions.send(t("error.restoreNotification"), "error"));
      }
    }
  };

  const formatComments = (comments) => {
    return comments?.length > 0 ? 
      <div className={classes.commentsBox}>
        {comments.map((data, index) => {
          let newData = {...data};

          if (data.comment.includes("auto.dismissed") && data.user.name === "Teleporte") {
            const [ commentToTranslate, name ] = data.comment.split("#");
            newData.comment = t(`notifications.autoDismissed.${commentToTranslate}`, { user: name });
          }

          return <NotificationComment key={index} data={newData} />;
        })}
      </div> : 
      <Placeholder message={t("fallbacks.noCommentsFound")} icon={<ChatOutlinedIcon/>}/>;
  };

  const showComments = async () => {
    let comments = [];
    
    try {
      const result = await notificationService.fetchComments({
        notificationId: alert.id
      });
      comments = result.data;

    } catch (e) {
      console.warn(e);
      dispatch(alertActions.send(t("error.fetchComments"), "error"));
    }

    props.createModal({
      type: "formCreator",
      title: t("label.comments"),
      submit: true,
      modalStyle: classes.actionModal,
      clearIcon: true,
      description: formatComments(comments),
      formOptions: [{
        field: "comment",
        type: "textField",
        submitOnEnter: true,
        autoComplete: "off",
        autoFocus: true
      }],
      setValue: commentForm.setValue,
      control: commentForm.control,
      errors: commentForm.formState.errors,
      confirm: t("button.comment"),
      clearErrors: commentForm.clearErrors,
      handleSubmit: commentForm.handleSubmit(submitComment)
    });
  };

  const showImage = async () => {
    try {
      const result = await lockNoteService.fetchImage({
        id: alert.payload.note_photo_uuid
      });

      props.createModal({
        type: "alert",
        title: t("label.notePhoto"),
        description: <img src={`data:image/jpeg;base64,${result.data}`} alt="Note image" />,
        confirm: t("button.close"),
        modalStyle: classes.photoModal
      });

    } catch (e) {
      console.warn(e);
      dispatch(alertActions.send(t("error.fetchImage"), "error"));
    }
  };

  return (
    <ErrorBoundary>
      <Card ref={ref} className={cx(classes.root)} data-testid={`notificationCard-${alert.id}`}>
        <div className={cx(classes.details, { [classes.critical]: criticality === 1, [classes.dismissed]: state.resolved })}>
          <CardContent className={classes.content}>
            <div className={classes.title}>
              {getIcon(alert.type.name)}
              <span className={classes.titleText}>{alert.payload?.title || titles[alert.type.name]}</span>
            </div>
            <div className={classes.message}>
              {getMessage(alert.type.name)}
            </div>
            {state.updatedBy && state.resolved ?
              <Fade in={state.resolved} mountOnEnter unmountOnExit timeout={{ enter: 500, exit: 500 }}>
                <p className={classes.updatedByText}>{t("label.dismissedBy", { name: state.updatedBy })}</p>
              </Fade> : null}
          </CardContent>
          <div className={classes.actions}>
            {state.timestamp ?
              <p className={classes.date}>{moment(state.timestamp).format("lll")}</p> : <p className={classes.date}></p>}
            {alert?.payload?.note_photo_uuid ?
              <Tooltip classes={{ tooltip: classes.tooltip }} title={t("label.viewPhoto")}>
                <IconButton size="small" aria-label="view photo" className={classes.actionButtons} onClick={showImage}>
                  <InsertPhotoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip> : null}
            <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.comments")}>
              <IconButton size="small" aria-label="comments" className={classes.actionButtons} onClick={showComments}>
                <ChatBubbleOutlineIcon fontSize="small" /> <span className={classes.commentCount}>{state.commentCount}</span>
              </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={state.resolved ? t("button.restore") : t("button.dismiss")}>
              <IconButton aria-label={state.resolved ? "restore" : "dismiss"} className={classes.actionButtons} onClick={flipResolvedStatus} size="small">
                {state.resolved ? <UndoOutlinedIcon fontSize="small" /> : <DoneIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Card >
    </ErrorBoundary>);
});

NotificationCard.propTypes = {
  history: PropTypes.object,
  alert: PropTypes.object,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  showDismissed: PropTypes.bool
};

NotificationCard.displayName = "NotificationCard";

export default NotificationCard;
