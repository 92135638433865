const workSessionsTableStyle = (theme) => ({
  chip: {
    "marginBottom": theme.spacing(2),
    "fontSize": theme.lsyPalette.subtitle3,
    "& .MuiChip-deleteIcon": {
      width: "20px"
    }
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "width": "fit-content",
    "& div": {
      minHeight: "auto"
    },
    "& p": {
      fontSize: theme.lsyPalette.subtitle3
    },
    "& button": {
      "fontSize": theme.lsyPalette.subtitle3,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.lsyPalette.subtitle3,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    [theme.breakpoints.down("lg")]: {
      "& .MuiTablePagination-selectLabel": {
        display: "none"
      }
    },
    "& .MuiToolbar-root": {
      padding: 0
    }
  },
  placeholderText: {
    fontSize: theme.lsyPalette.body2
  },
  placeholderIcon: {
    fontSize: "38px"
  },
  spaceAround: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  width: {
    width: "inherit"
  }
});

export default workSessionsTableStyle;