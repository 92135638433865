import { useState, useEffect, useCallback, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { alertActions } from "_actions";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useCustomCompareMemo } from "use-custom-compare";
import { isEqual } from "lodash";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// Services
import { userService, keyService, lockService } from "_services/lockstasy";
import { hasLockLicense } from "_services/lockstasy/helper";

// @mui/material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SiteIcon from "assets/teleporte/SiteIcon";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";
import PersonIcon from "@mui/icons-material/Person";
import DialpadIcon from "@mui/icons-material/Dialpad";

// Components
import GridItem from "components/Grid/GridItem";
import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import TableWidget2 from "_containers/Widgets/TableWidget2";
import LockDetailsWidget from "_containers/Widgets/LockDetailsWidget";
import LockNoteWidget from "_containers/Widgets/LockNoteWidget";
import CustomModal from "_components/Modal/CustomModal";
import AccessWidget from "_containers/Widgets/AccessWidget";
import EmbeddedMap from "_containers/Widgets/Maps/EmbeddedMap";
import ErrorBoundary from "_components/ErrorBoundary";

// Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/lockStyle.js";
import { lsyRouter } from "_helpers";
import { fetchErrors } from "_utils";

// Constants
const PAGE_SIZE = 10;

const useStyles = makeStyles()(styles);

function Lock(props) {
  const { org, history } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const params = useParams();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const { handleSubmit, setValue, formState: { errors }, control, clearErrors, reset } = useForm();
  const [lock, setLock] = useState({});
  const [refreshKeys, setRefreshKeys] = useState(false);
  const [keyModal, setKeyModal] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: false,
      data: {},
      fetchWidgetData: null
    }
  );
  const [wizard, setWizard] = useState({ type: "key", open: false, key: 0, fetchWidgetData: null });
  const [errorModal, setErrorModal] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: false,
      description: null
    }
  );
  const setErrorModalOpen = (open) => setErrorModal({ open });

  const ability = lsyAdminDataContext.ability;
  const isRegularUser = false;

  const fetchLock = useCallback(async () => {
    try {
      const result = await lockService.fetchLock({ lockId: params.id });
      setLock(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
      return [];
    }
  }, [params.id]);

  const fetchKeys = async (options = {}) => {
    options.lock_ids = `[${params.id}]`;
    if (options.membership_ids && options.membership_ids.length > 0) {
      options.membership_ids = `[${options.membership_ids.map(user => user.id)}]`;
    }
    if (options.start_date) {
      options.start_date = options.start_date.startOf("day");
    }
    if (options.end_date) {
      options.end_date = options.end_date.endOf("day");
    }
    try {
      const result = await keyService.fetchKeys({ ...options });
      return result;
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors[0].detail === "error.invalid.start_date.gt.end_date") {
        dispatch(alertActions.send(t("error.invalidDates"), "error"));
      } else {
        dispatch(alertActions.send(t("error.fetchKeys"), "error"));
      }

      console.warn("Warning, failed to fetch keys", e);
      return [];
    }
  };

  const fetchUsersWithInput = useCallback(async (inputValue) => {
    try {
      const result = await userService.fetchUsers({
        page: 1,
        page_index: PAGE_SIZE,
        search: inputValue
      });
      const formattedResult = result.data.map((v) =>
        v
          ? {
            id: v.membership_id,
            name: `${v.first_name} ${v.last_name} (${v.email})`
          }
          : null
      );

      return formattedResult;
    } catch (e) {
      console.warn("Warning, failed to fetch locks with given input", e);
    }
  }, []);

  const updateKey = async (data) => {
    try {
      await keyService.updateKey({ id: keyModal.data.id, data: { key: data } });
      keyModal.fetchWidgetData();
      dispatch(alertActions.send(t("success.updateKey")));
    } catch (e) {
      console.warn("Warning, failed to update key", e);
      dispatch(alertActions.send(t("error.updateKey"), "error"));
    }
  };

  const deleteKey = async (id) => {
    try {
      await keyService.deleteKey({ keyId: id });
      keyModal.fetchWidgetData();
      dispatch(alertActions.send(t("success.deleteKey")));
    } catch (e) {
      console.warn("Warning, failed to delete key", e);
      dispatch(alertActions.send(t("error.deleteKey"), "error"));
    }
  };

  const onSubmit = (data) => {
    if (keyModal.type !== "lockGroup") {
      updateKey(data);
    }
    setKeyModal({ open: false });
  };

  useEffect(() => {
    fetchLock();
  }, [fetchLock]);

  const handleDeleteKey = () => {
    if (keyModal.type === "regular") {
      deleteKey(keyModal.data.id);
    } else {
      const name = keyModal.type === "site" ? keyModal.data.site?.name : keyModal.data.lock_group?.name;
      const link = keyModal.type === "site" ? lsyRouter("site", keyModal.data.site_id) : lsyRouter("lock_group", keyModal.data.lock_group_id);
      const instruction = keyModal.type === "site" ? "instructions.manageSiteKeys" : "instructions.manageLockGroupKeys";
      const error = keyModal.type === "site" ? "error.deleteKeyTypeSite" : "error.deleteKeyTypeLockGroup";

      const description = <div>
        <p className={classes.deleteKeyMsg}>{t(error, { name: name })}</p>
        <p>{t(instruction, { link: <span className={classes.actionLink} onClick={() => history.push(link)}>{t("label.here")}</span> })}</p>
      </div>;

      setErrorModal({
        open: true,
        description: description
      });
    }
  };

  const getFilterOptions = () => {
    const options = [
      {
        field: "status",
        type: "select",
        label: t("label.status"),
        defaultValue: "0",
        options: [
          { name: t("label.active"), value: "0" },
          { name: t("label.deactivated"), value: "1" },
          { name: t("label.expired"), value: "2" },
          { name: t("label.all"), value: " " }
        ]
      },
      {
        field: "start_date",
        type: "datePicker",
        label: t("label.startDate"),
        defaultValue: null,
        clearable: true
      },
      {
        field: "end_date",
        type: "datePicker",
        label: t("label.endDate"),
        defaultValue: null,
        clearable: true
      }
    ];


    if (!isRegularUser) {
      options.unshift({
        field: "membership_ids",
        type: "typeAhead",
        autoFocus: true,
        defaultValue: [],
        placeholder: t("label.searchUsers"),
        async: true,
        isMulti: true,
        promiseOptions: fetchUsersWithInput
      });
    }


    return options;
  };

  const keyFormatter = (data, fetchWidgetData) => {
    var headers = [
      { id: "type", label: t("widgetField.type"), sortable: false },
      { id: "user", label: t("widgetField.user"), sortable: false },
      { id: "validFrom", label: t("label.validFrom"), sortable: true },
      { id: "validTill", label: t("label.validUntil"), sortable: true },
      { id: "issuedBy", label: t("label.issuedBy"), sortable: false },
      { id: "updatedAt", label: t("label.updatedAt"), sortable: true },
      { id: "reference", label: t("label.reference"), sortable: false },
      { id: "comments", label: t("label.comments"), sortable: false }
    ];

    const getIcon = (v) => {
      let icon;
      if (v.lock_group_id) {
        icon = (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={v.lock_group.name}
          >
            <div className={classes.iconDiv}>
              <LockGroupIcon
                height="25px"
                width="25px"
                onClick={() => {
                  if (ability.can("read", "lock_groups")) {
                    history.push(lsyRouter("lock_group", v.lock_group_id));
                  }
                }}
              />
            </div>
          </Tooltip>
        );
      } else if (v.site_id) {
        icon = <SiteIcon width="25px" height="25px" />;
      } else if (v.fsu) {
        icon = <DialpadIcon width="25px" height="25px" />;
      } else {
        icon = <PersonIcon width="25px" height="25px" className={classes.invisIcon} />;
      }

      return icon;
    };

    var tableData = data.map((v) => {
      var tempData = {
        type: {
          text: <div className={classes.typeIconWrap}>{getIcon(v)}</div>
        },
        user: {
          text: v.user.name,
          action: ability.can("read", "users") && !isRegularUser ? () => {
            history.push(lsyRouter("user", v.user.membership_id));
          } : null
        },
        validFrom: {
          text: moment(v.start_date).format("lll")
        },
        validTill: {
          text: moment(v.end_date).format("lll"),
          key_status: v.active_status
        },
        issuedBy: {
          text: v.issuer.name
        },
        updatedAt: {
          text: moment(v.updated_at).format("lll")
        },
        reference: {
          text: v.ticket_number
        },
        comments: {
          text: v.comments
        },
        rowAction: () => {
          setKeyModal({ open: true, fetchWidgetData: fetchWidgetData, data: v, type: v.lock_group_id ? "lockGroup" : v.site_id ? "site" : "regular" });
        }
      };
      return tempData;
    });

    return [headers, tableData];
  };

  const keyDescription = useCustomCompareMemo(() => {
    const data = keyModal.data || {};
    return (<Grid className={classes.details} container direction="column">
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("label.validFrom")}: </span>
        <span>{moment(data.start_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("label.validUntil")}: </span>
        <span>{moment(data.end_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("widgetField.user")}: </span>
        <span>{`${data.user?.name} (${data.user?.email})`}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("widgetField.lock")}: </span>
        <span>{data.lock_name}</span>
      </GridItem>
    </Grid>);
  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const canUpdateKey = () => {
    if (lock.semaphore_locked) {
      return false;
    }

    if (keyModal.type === "regular") {
      return ability.can("update", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("update", "lock_collection_keys");
    } else {
      return ability.can("update", "lock_group_keys");
    }
  };

  const form = useCustomCompareMemo(() => {
    const { data, type } = keyModal;
    if (type !== "lockGroup" && canUpdateKey()) {
      return [
        {
          field: "ticket_number",
          type: "textField",
          label: t("label.referenceNumber"),
          defaultValue: data?.ticket_number || "",
          autoFocus: true,
          placeholder: `${t("label.maxChars", { num: 50 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 50
          }
        },
        {
          field: "comments",
          type: "textField",
          label: t("label.comments"),
          defaultValue: data?.comments || "",
          placeholder: `${t("label.maxChars", { num: 100 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 100
          }
        }
      ];
    } else {
      return null;
    }

  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const resetKeyDetailsFormFields = () => {
    if (form) {
      let formFields = {};
      for (const field of form) {
        formFields[field.field] = field.defaultValue;
      }

      reset(formFields);
    }
  };

  const canDeleteKey = () => {
    if (keyModal.data.fsu || lock.semaphore_locked) {
      return false;
    }
    
    if (keyModal.type === "regular") {
      return ability.can("delete", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("delete", "lock_collection_keys");
    } else {
      return ability.can("delete", "lock_group_keys");
    }
  };

  const getKeysCustomButtons = () => {
    let buttons = [];

    if (ability.can("create", "keys")) {
      buttons.push({
        label: lock.semaphore_locked ? t("error.lock.locked") : t("button.issueKey"),
        icon: <AddIcon className={!lock.semaphore_locked ? classes.icon : null} />,
        disabled: lock.semaphore_locked,
        action: (fetchWidgetData) => {
          setWizard(prev => {
            return { ...prev, fetchWidgetData, type: "lockKey", open: true, key: Math.random() };
          });
        }
      });
    }

    return buttons;
  };

  return (
    <div className={classes.lsyBackground}>
      {wizard.open ?
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          setOpen={setWizard}
          fetchWidgetData={wizard.fetchWidgetData}
          org={org}
          type={wizard.type}
          lock={lock}
        /> : null}
      <CustomModal
        open={errorModal.open}
        setOpen={setErrorModalOpen}
        title={t("label.error")}
        type="alert"
        description={errorModal.description}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModal
          open={keyModal.open}
          setOpen={() => setKeyModal({ open: false })}
          onOpen={resetKeyDetailsFormFields}
          handleSubmit={handleSubmit(onSubmit)}
          title={t("label.keyDetails")}
          type="formCreator"
          modalStyle={classes.editKeyModal}
          description={keyDescription}
          handleCancel={handleDeleteKey}
          additionalClose={!canUpdateKey()}
          confirm={(keyModal.type !== "lockGroup" && canUpdateKey()) ? t("button.save") : null}
          cancel={t("button.deleteKey")}
          confirmDisabled={!canUpdateKey()}
          cancelDisabled={!canDeleteKey()}
          submit
          clearIcon
          manualClose
          errors={errors}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          formOptions={form}
        />
      </form>
      <Grid container justifyContent="space-evenly" className={classes.container}>
        <GridItem xs={12} lg={6} className={classes.LockDetailsWidget}>
          <ErrorBoundary>
            <LockDetailsWidget
              lock={lock}
              history={history}
              setRefreshKeys={setRefreshKeys}
            />
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem sx={{ display: { md: "block", xs: "none" } }}/>
        <Divider className={classes.divider} sx={{ display: { lg: "none", xs: "block" } }} />
        <GridItem xs={12} lg={5} className={classes.accessWidgetWrap}>
          <ErrorBoundary>
            <Can I="read" on="access_history" ability={ability}>
              <AccessWidget
                lock={lock}
                org={org}
                history={history}
                isRegularUser={isRegularUser}
                type="lock"
              />
            </Can>
          </ErrorBoundary>
        </GridItem>
        <Divider className={classes.divider} />
        <Grid item xs={12} md={12} className={classes.tableWidgetItem}>
          <ErrorBoundary>
            <TableWidget2
              org={org}
              paginate
              refresh={refreshKeys}
              appBarSize="sm"
              title={t("label.keys")}
              rowsPerPage={8}
              fetchData={fetchKeys}
              dataFormatter={keyFormatter}
              defaultSort="updatedAt"
              sortMap={{ user: "user.name", validFrom: "start_date", validTill: "end_date", issuedBy: "issuer.name", updatedAt: "updated_at" }}
              sortDirection="desc"
              fallbackData={t("fallbacks.noKeysFound")}
              customButtons={getKeysCustomButtons()}
              enableFilter
              filterOptions={getFilterOptions()}
              defaultValues={{
                lock_ids: [],
                status: "0"
              }}
              removeEmptyOptions
              setRefreshKeys={setRefreshKeys}
            />
          </ErrorBoundary>
        </Grid>
        <Divider className={classes.divider} />
        <GridItem  className={classes.mapWrap}>
          <ErrorBoundary>
            <EmbeddedMap
              center={lock.location}
              hasLockLicense={hasLockLicense("maps", lock)}
            />
          </ErrorBoundary>
        </GridItem>
        <Divider className={classes.divider}/>
        <GridItem xs={12}>
          <ErrorBoundary>
            <LockNoteWidget
              org={org}
              history={history}
              isRegularUser={isRegularUser}
              type="lock"
              lock={lock}
            />
          </ErrorBoundary>
        </GridItem>
      </Grid>
    </div>
  );
}

Lock.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  setLockstasyPath: PropTypes.func,
  baseUrl: PropTypes.string
};

export default Lock;
