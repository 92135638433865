const lockNoteDetailsWidgetStyle = (theme) => ({
  redirectLink: {
    color: theme.lsyPalette.link,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    maxWidth: "100px"
  },
  redirectIcon: {
    width: "20px",
    height: "20px"
  },
  cardContainer: {
    position: "relative",
    height: "100%",
    borderColor: theme.palette.text.light,
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      marginBottom: theme.spacing(3),
      borderBottom: "solid",
      borderColor: theme.palette.text.light
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap"
  },
  cardHeader: {
    margin: `${theme.spacing(1.5)} 0`
  },
  cardHeaderItem: {
    color: theme.palette.secondary.dark,
    fontSize: theme.lsyPalette.body1,
    display: "flex",
    alignItems: "center"
  },
  cardHeaderIcon: {
    width: theme.lsyPalette.title2,
    height: theme.lsyPalette.title2,
    marginRight: theme.spacing(0.75)
  },
  tableItemTime: {
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.chipFontSize,
    marginBottom: theme.spacing(0.5)
  },
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    color: theme.palette.secondary.dark,
    overflowWrap: "anywhere"
  },
  cardBody: {
    position: "sticky",
    top: "5vh",
    width: "100%"
  },
  imageContainer: {
    display: "flex",
    position: "relative",
    width: "auto",
    justifyContent: "center"
  },
  noteImage: {
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    objectFit: "cover",
    width: "auto",
    maxWidth: "100%"
  },
  textContainer: {
    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`
  },
  placeholder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  placeholderIcon: {
    fontSize: "48px",
    margin: "1rem",
    color: theme.lsyPalette.primary.mainDark
  },
  placeholderText: {
    fontWeight: theme.lsyPalette.heavyWeight,
    textAlign: "center",
    fontSize: "16px",
    color: theme.lsyPalette.primary.mainDark
  },
  clickable: {
    cursor: "pointer"
  },
  info: {
    height: "50%"
  },
  placeholderMargin: {
    marginTop: theme.spacing(9)
  },
  marginRight: {
    marginRight: theme.spacing(2)
  },
  hiddenOverflow: {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  infoLine: {
    display: "flex",
    alignItems: "center"
  },
  replacementDescription: {
    color: theme.lsyPalette.primary.mainLight
  },
  value: {
    marginLeft: theme.spacing(1)
  },
  spaceAround: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  marginBottom: {
    marginBottom: theme.spacing(3)
  },
  spaceSidesBottom: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  icon: {
    with: "20px",
    height: "20px"
  }
});

export default lockNoteDetailsWidgetStyle;