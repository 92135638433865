import { useState, useCallback, useReducer, Fragment } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useCustomCompareEffect, useCustomCompareCallback } from "use-custom-compare";
import { isEqual } from "lodash";
import moment from "moment";
import ReactECharts from "echarts-for-react";
import queryString from "query-string";

import { alertActions } from "_actions";
import { accessHistoryService } from "_services";
import { hasLockLicense } from "_services/lockstasy/helper";
import { getLocalTZ, lsyRouter } from "_helpers";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ToggleButton from "@mui/material/ToggleButton";
import Grid from "@mui/material/Grid";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//components
import CustomModal from "_components/Modal/CustomModal";

//icons
import { GetApp } from "@mui/icons-material";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import DvrIcon from "@mui/icons-material/Dvr";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/accessWidgetStyle.js";

const useStyles = makeStyles()(styles);

const exportOptions = Object.freeze([{
  name: "XLSX",
  value: true
},
{
  name: "CSV",
  value: false
}]);

function AccessWidget(props) {
  const { user, lock, history, isRegularUser, type } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      data: [],
      exportModalOpen: false,
      loading: true
    });
  const setLoading = (loading) => setState({ loading });
  const setExportModalOpen = (exportModalOpen) => setState({ exportModalOpen });
  const [range, setRange] = useState("30d");
  const [exportType, setExportType] = useState("date");
  const { handleSubmit, setValue, formState: { errors }, control } = useForm();

  const handleRange = (newAlignment) => {
    if (newAlignment) {
      setRange(newAlignment);
    }
  };

  const fetchAccessHistoryStats = async () => {
    try {
      if (type === "user"){
        if (!user.membership_id) 
          return;
      } else if (type === "lock"){
        if (!lock.id) 
          return;
      }

      let startDate;
      let groupBy;
      switch (range) {
        case "30d": {
          startDate = moment().subtract(29, "days").startOf("day");
          groupBy = "day";
        }
          break;
        case "12w":
          {
            startDate = moment().subtract(12, "weeks").startOf("day");
            groupBy = "week";
          }
          break;
        case "6m": {
          startDate = moment().subtract(6, "months").startOf("day");
          groupBy = "month";
        }
          break;
        default:
          break;
      }
      startDate = startDate.format("YYYY-MM-DD");

      let options = {
        start_date: startDate,
        end_date: moment().endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
        group_by: groupBy,
        client_timezone: getLocalTZ()
      };

      let result;

      if (type === "user"){
        options["membershipId"] = user.membership_id;
        result = await accessHistoryService.fetchAccessHistoryStats(options);
      } else if (type === "lock"){
        options["lockId"] = lock.id;
        result = await accessHistoryService.fetchLockAccessHistoryStats(options);
      }

      let data = result.data;
      Object.keys(data)?.forEach((date) => {
        let newDate = date.split(" ")[0];
        data[newDate] = data[date];
        delete data[date];
      });
      setState({ data: data, loading: false });
    } catch (e) {
      console.warn("Warning, failed to fetch user's access history data", e);
      dispatch(alertActions.send(t("error.fetchAccessHistory"), "error"));
    }
    setLoading(false);
  };

  const getXAxis = useCallback(() => {
    const count = range.substring(0, range.length - 1);
    let type;
    let axis = [];
    switch (range) {
      case "6m":
        type = "months";
        break;
      case "12w":
        type = "weeks";
        break;
      case "30d":
        type = "days";
        break;
      default:
        break;
    }

    for (let i = 0; i < count; i++) {
      axis.push(moment().subtract(i, type).endOf(type.substring(0, type.length - 1)).format("YYYY-MM-DD"));
    }
    axis = axis.reverse();

    return axis;
  }, [range]);

  const getOptions = useCustomCompareCallback(() => {
    const xAxis = getXAxis();
    const dataPoints = xAxis.map((date) => {
      return state.data[date] || 0;
    });

    const options = {
      xAxis: {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: "value",
        minInterval: 1
      },
      series: [
        {
          data: dataPoints,
          type: "line"
        }
      ],
      tooltip: {
        trigger: "axis"
      },
      grid: {
        left: "30", right: "0", top: "15"
      }
    };

    return options;
  }, [state.data, range], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useCustomCompareEffect(() => {
    if (type === "user"){
      if (user?.membership_id) {
        setState({ user });
      }
    } else if (type === "lock"){
      if (lock?.id){
        setState({ lock });
      }
    }
    fetchAccessHistoryStats();
  }, [user, lock, range], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  
  const exportAccessHistoryData = async (data) => {
    try {
      if (exportType === "date") {
        data.start_date = moment(data.start_date).startOf("day");
        data.end_date = moment(data.end_date).endOf("day").utc();
      } else {
        delete data.start_date;
        delete data.end_date;
      }

      if (type === "user"){
        await accessHistoryService.exportAccessHistory({ membershipId: user.membership_id, data: data });
      } else if (type === "lock"){
        await accessHistoryService.exportLockAccessHistory({ lockId: lock.id, data: data });
      }
      dispatch(alertActions.send(t("success.exportAccessHistory")));
    } catch (e) {
      console.warn("Warning, failed to export access history", e);
      dispatch(alertActions.send(t("error.exportAccessHistory"), "error"));
    }
  };

  const handleKeyDownExportType = (event) => {
    if (event.key === "Tab") {
      setExportType(event.currentTarget.dataset.value);
    }
  };

  const handleExportTypeChange = (event) => setExportType(event.target.value);

  const exportTypeInput = <GridItem xs={12} className={classes.marginTop}>
    <FormControl variant="outlined" className={classes.itemGrid}>
      <InputLabel id="exportType">
        {t("label.exportType")}
      </InputLabel>
      <Select
        className={classes.itemBox}
        value={exportType}
        role="listbox"
        label={t("label.exportType")}
        data-testid="exportTypeId"
        onChange={handleExportTypeChange}
      >
        <MenuItem
          value="date"
          onKeyDown={handleKeyDownExportType}
        >
          {t("label.exportLogsBetweenDates")}
        </MenuItem>
        <MenuItem
          value="all"
          onKeyDown={handleKeyDownExportType}
        >
          {t("label.exportAllLogs")}
        </MenuItem>
      </Select>
    </FormControl>
  </GridItem>;

  const getExportForm = () => {
    let options = [{
      field: "xlsx",
      type: "select",
      label: t("label.exportFormat"),
      defaultValue: true,
      options: exportOptions
    }];

    if (exportType === "date") {
      options = options.concat([
        {
          field: "start_date",
          type: "datePicker",
          label: t("label.startDate"),
          defaultValue: moment().subtract(1, "months"),
          disableFuture: true
        },
        {
          field: "end_date",
          type: "datePicker",
          label: t("label.endDate"),
          defaultValue: moment().add(1, "days")
        }]);
    }

    return options;
  };

  const onExportSubmit = (data) => {
    if (exportType === "date" && moment(data.end_date).isSameOrBefore(data.start_date)) {
      dispatch(alertActions.send(t("error.invalidDates"), "error"));
    } else {
      setExportModalOpen(false);
      data.client_timezone = getLocalTZ();
      exportAccessHistoryData(data);
    }
  };

  const navigateToSystemLogs = () => {
    history.push(`/system_logs?${queryString.stringify({ membership_ids: [user.membership_id] })}`);
  };

  return (
    <Fragment>
      <CustomModal
        open={state.exportModalOpen}
        setOpen={setExportModalOpen}
        handleSubmit={handleSubmit(onExportSubmit)}
        title={t("label.exportAccessHistory")}
        type="formCreator"
        modalStyle={classes.modal}
        confirm={t("actions.export")}
        cancel={t("button.cancel")}
        submit
        manualClose
        errors={errors}
        control={control}
        setValue={setValue}
        description={exportTypeInput}
        formOptions={getExportForm()}
      />
      <div className={classes.topBar}>
        {<span className={classes.name}>{t("label.accessHistory")}</span>}
        {!isRegularUser ?
          <Fragment>
            {type === "user" ? (
              <>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("label.showSystemActivity")}
                >
                  <IconButton
                    className={classes.iconButton}
                    size="small"
                    onClick={navigateToSystemLogs}
                  >
                    <DvrIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("label.viewAccessHistory")}
                >
                  <span>
                    <IconButton
                      className={classes.iconButton}
                      aria-label={t("label.viewAccessHistory")}
                      size="small"
                      onClick={() =>
                        history.push(
                          lsyRouter("user_access_histories", user.membership_id)
                        )
                      }
                    >
                      <FindInPageOutlinedIcon className={classes.icon} />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ) : null}
            {
              type === "lock" ? (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={t("label.viewAccessHistory")}
                >
                  <span>
                    <IconButton
                      className={classes.iconButton}
                      aria-label={t("label.viewAccessHistory")}
                      size="small"
                      disabled={!hasLockLicense("access_history", lock)}
                      onClick={() =>
                        history.push(
                          lsyRouter("lock_access_histories", lock.id)
                        )
                      }
                    >
                      <FindInPageOutlinedIcon className={!hasLockLicense("access_history", lock) ? null : classes.icon} />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null
            }

            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={t("label.exportAccessHistory")}
            >
              <span>
                <IconButton 
                  aria-label={t("label.exportAccessHistory")}
                  className={classes.iconButton} 
                  size="small" 
                  disabled={ type === "lock" ? !hasLockLicense("access_history", lock) : false } // disable export for a lock with no license
                  onClick={() => setExportModalOpen(true)}>
                  <GetApp className={type === "lock" && !hasLockLicense("access_history", lock) ? null : classes.icon} />
                </IconButton>
              </span>
            </Tooltip>
          </Fragment> : null}
      </div>
      <Divider className={classes.divider} />
      <Grid container direction="column" alignItems="flex-end">
        <Grid item style={{ marginTop: "5px", marginLeft: "auto", width: "fit-content" }}>
          <ToggleButton
            className={cx(classes.toggleButton, { [classes.buttonActive]: range === "30d", [classes.buttonInactive]: range !== "30d" })}
            value="30d"
            selected={range === "30d"}
            onClick={() => handleRange("30d")}
            aria-label="30 days">{t("button.range.30D")}</ToggleButton>
          <ToggleButton
            className={cx(classes.toggleButton, { [classes.buttonActive]: range === "12w", [classes.buttonInactive]: range !== "12w" })}
            value="12w"
            selected={range === "12w"}
            onClick={() => handleRange("12w")}
            aria-label="12 weeks">{t("button.range.12W")}</ToggleButton>
          <ToggleButton
            className={cx(classes.toggleButton, { [classes.buttonActive]: range === "6m", [classes.buttonInactive]: range !== "6m" })}
            value="6m"
            selected={range === "6m"}
            onClick={() => handleRange("6m")}
            aria-label="6 months">{t("button.range.6M")}</ToggleButton>
        </Grid>
      </Grid>
      <ReactECharts
        option={getOptions()}
        notMerge={true}
        lazyUpdate={true}
      />
    </Fragment>
  );
}

AccessWidget.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  user: PropTypes.object,
  lock: PropTypes.object,
  isLoaded: PropTypes.bool,
  isRegularUser: PropTypes.bool,
  type: PropTypes.string
};

export default AccessWidget;
