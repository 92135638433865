const userStyle = (theme) => ({
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer",
    fontSize: theme.lsyPalette.body1
  },
  lsyBackground: {
    backgroundColor: theme.lsyPalette.secondary.background,
    minHeight: "100%",
    padding: "10px 5% 10px 5%"
  },
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  typeIconWrap: {
    display: "flex",
    alignItem: "center",
    padding: "3px 0px"
  },
  editKeyModal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "600px"
  },
  keyValueItem: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.body1,
    "marginBottom": "5px",
    "& span": {
      "&:nth-of-type(2)": {
        fontWeight: theme.lsyPalette.heavyWeight
      }
    }
  },
  details: {
    marginBottom: "10px"
  },
  deleteKeyMsg: {
    marginTop: "0px"
  },
  container: {
    backgroundColor: theme.lsyPalette.secondary.main,
    borderRadius: "3px",
    marginBottom: "25px"
  },
  iconDiv: {
    width: "fit-content"
  },
  tableWidgetItem: {
    paddingLeft: "3%",
    paddingRight: "3%"
  },
  divider: {
    width: "100%"
  },
  invisIcon: {
    visibility: "hidden"
  }
});

export default userStyle;
