export const adminSchema = Object.freeze({
  users: { read: true, create: true, update: true, delete: true, devices: { read: true, delete: true } },
  locks: { read: true, update: true, replacements: { create: true }  },
  lock_notes: { read: true },
  lock_logs: { read: true },
  keys: { read: true, create: true, update: true, delete: true },
  lock_groups: { read: true, create: true, update: true, delete: true },
  lock_collections: { read: true, create: true, update: true, delete: true },
  lock_collection_keys: { read: true, create: true, update: true, delete: true },
  lock_group_keys: { read: true, create: true, update: true, delete: true },
  access_requests: { read: true, create: true, update: true },
  access_history: { read: true },
  locations: { read: true },
  system_logs: { read: true },
  logs: { read: true },
  tags: { read: true, create: true, update: true, delete: true },
  reports: { read: true, create: true },
  lock_events: { read: true },
  charts: { read: true },
  work_sessions: { read: true },
  notifications: { read: true, update: true },
  webhooks: { read: true, create: true, update: true, delete: true },
  organizations: { read: true, create: true, update: true, delete: true },
  api_tokens: { read: true, create: true, update: true, delete: true },
  roles: { update: true },
  dashboard: { read: true },
  devices: { read: true, delete: true },
  multiple_devices: {use: true},
  lock_status: { read: true }
});

export const emptySchema = Object.freeze({
  users: { read: false, create: false, update: false, delete: false, devices: { read: false, delete: false } },
  locks: { read: false, update: false, replacements: { create: false } },
  lock_notes: { read: false },
  lock_logs: { read: false },
  keys: { read: false, create: false, update: false, delete: false },
  lock_groups: { read: false, create: false, update: false, delete: false },
  lock_collections: { read: false, create: false, update: false, delete: false },
  lock_collection_keys: { read: false, create: false, update: false, delete: false },
  lock_group_keys: { read: false, create: false, update: false, delete: false },
  access_requests: { read: false, create: false, update: false },
  access_history: { read: false },
  locations: { read: false },
  system_logs: { read: false },
  logs: { read: false },
  tags: { read: false, create: false, update: false, delete: false },
  reports: { read: false, create: false },
  lock_events: { read: false },
  charts: { read: false },
  work_sessions: { read: false },
  notifications: { read: false, update: false },
  webhooks: { read: false, create: false, update: false, delete: false },
  organizations: { read: false, create: false, update: false, delete: false },
  api_tokens: { read: false, create: false, update: false, delete: false },
  roles: { update: false },
  dashboard: { read: false },
  devices: { read: false, delete: false },
  multiple_devices: {use: false},
  lock_status: { read: false }
});

export const adminRules = Object.freeze({ read: true, create: true, update: true, delete: true });
