import { useContext } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { alertActions } from "_actions";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { invalidTagRegex, invalidSearchCharsRegex } from "_helpers";
import { tagService } from "_services/lockstasy";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import AddIcon from "@mui/icons-material/Add";
import TagIcon from "@mui/icons-material/LocalOfferOutlined";

// styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/tagsStyle.js";

import CardListWidget from "_containers/Widgets/CardListWidget";
import TagsCard2 from "_components/Lockstasy/TagsCard2";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Placeholder from "_components/Helper/Placeholder";


const useStyles = makeStyles()(styles);

function LsyTags(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const fetchTags = async (options) => {
    try {
      const result = await tagService.fetchTags(options);
      return result;
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  const createTag = async (data, fetchWidgetData) => {
    try {
      await tagService.createTag({ data: data });
      dispatch(alertActions.send(t("success.createTag")));
    } catch (e) {
      console.warn("Warning, failed to create tag", e);
      dispatch(alertActions.send(t("error.createTag"), "error"));
    }

    setTimeout(() => {
      fetchWidgetData();
    }, [1000]);
  };

  const skeletonCard = <Card className={classes.card}>
    <div className={classes.divBar} />
    <GridContainer direction="column" alignItems="center">
      <GridItem xs={8}>
        <Skeleton variant="text" width={120} height={50} />
      </GridItem>
      <GridItem xs={8}>
        <Skeleton variant="text" width={180} height={30} />
      </GridItem>
    </GridContainer>
  </Card>;

  const tagFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset) => {
    var cardList = data.map((data, index) => {
      return (
        <GridItem key={data.id} xs={12} sm={6} md={4} lg={3}>
          <TagsCard2
            key={data.id}
            org={props.org}
            index={index}
            data={data}
            history={props.history}
            setFilterVariables={setFilterVariables}
            createModal={createModal}
            fetchWidgetData={fetchWidgetData}
            setValue={setValue}
            state={state}
            setState={setState}
            reset={reset}
          />
        </GridItem>
      );
    });
    return cardList;
  };

  const getAdditionOptions = () => { // we get all roles back but only add if schema is present for role
    return [{
      field: "name",
      type: "textField",
      label: t("form.name"),
      required: t("error.requiredField"),
      validateEntry: value => !invalidTagRegex.test(value),
      errorMsg: t("error.invalidCreateChars"),
      autoFocus: true
    }];
  };

  const getFilterOptions = () => {
    return [
      {
        field: "search",
        type: "textField",
        submitOnEnter: true,
        label: t("form.search"),
        validateEntry: value => !invalidSearchCharsRegex.test(value),
        errorMsg: t("error.invalidSearch"),
        autoFocus: true
      },
      {
        field: "sort_by",
        type: "select",
        label: t("form.sortTagsBy"),
        defaultValue: " ",
        options: [
          { name: t("form.allTags"), value: " " },
          { name: t("label.updatedAt"), value: "updated_at,desc" }
        ]
      }
    ];
  };

  const getDefaultValues = () => {
    var defaultValues = {
      search: "",
      sort: " "
    };
    return defaultValues;
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        <GridItem className={classes.tagWidget} xs={12} md={10}>
          <CardListWidget
            skeletonCard={skeletonCard}
            fallbackData={<Placeholder message={t("fallbacks.noTagsFound")} icon={<TagIcon/>}/>}
            org={props.org}
            paginate
            stats
            title={t("features.tags")}
            rowsPerPage={12}
            secondPaginate={true}
            enableFilter
            filterOptions={getFilterOptions()}
            fielterDefaultValues={getDefaultValues()}
            removeEmptyOptions
            additionForm={getAdditionOptions()}
            additionIcon={<AddIcon className={classes.additionIcon} />}
            additionLabel={t("label.createTag")}
            addition={ability.can("create", "tags") ? createTag : null}
            fetchData={fetchTags}
            dataFormatter={tagFormatter}
            appBarSize="md"
            display="grid"
            enableQueryParams
            location={props.location}
          />
        </GridItem>
      </Grid>
    </div>
  );
}

LsyTags.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  location: PropTypes.object
};

export default LsyTags;
