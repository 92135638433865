const userDetailsWidgetStyle = theme => ({
  tooltip: {
    backgroundColor: theme.lsyPalette.primary.mainHover,
    color: theme.lsyPalette.textContrast
  },
  icon: {
    color: theme.lsyPalette.primary.main
  },
  passwordIcon: {
    color: theme.lsyPalette.primary.main,
    transform: "scale(1.4)"
  },
  modal: {
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    width: "500px"
  },
  noHover: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "inherit"
    }
  },
  rolesIcon: {
    fill: theme.lsyPalette.secondary.main,
    height: "30px",
    width: "30px"
  },
  siteLocationIcon: {
    color: theme.lsyPalette.primary.mainLight
  },
  helpIcon: {
    fill: theme.lsyPalette.primary.mainLight,
    transform: "scale(0.7)"
  },
  topBar: {
    marginTop: "5px",
    display: "flex",
    alignItems: "center"
  },
  skeleton: {
    width: "100%"
  },
  name: {
    flexGrow: 1,
    fontSize: theme.lsyPalette.body3,
    color: theme.lsyPalette.text,
    fontWeight: theme.lsyPalette.heavyWeight
  },
  table: {
    width: "fit-content",
    fontSize: theme.lsyPalette.body1,
    color: theme.lsyPalette.text,
    marginTop: "10px",
    minWidth: "350px",
    [theme.breakpoints.down("md")]: {
      minWidth: "0px"
    }
  },
  field: {
    color: theme.lsyPalette.primary.mainLight
  },
  divider: {
    borderColor: theme.lsyPalette.primary.mainLight
  },
  chip: {
    marginRight: "3px"
  },
  chips: {
    marginTop: "5px",
    marginBottom: "5px"
  },
  actionLink: {
    color: theme.lsyPalette.link,
    cursor: "pointer"
  },
  iconButton: {
    marginRight: "5px"
  },
  value: {
    width: "70%"
  },
  itemBox: {
    width: "100%",
    marginBottom: "15px",
    marginTop: "15px"
  },
  root: {
    width: "100%"
  },
  firmwareUpgradeIcon: {
    stroke: theme.lsyPalette.stdRed
  },
  defaultIcon: {
    height: "40px",
    width: "40px",
    marginLeft: "10px"
  },
  arrow: {
    fill: theme.lsyPalette.secondary.main
  },
  lockInfoIcons: {
    stroke: theme.lsyPalette.primary.mainLight
  },
  lockInfoIconSolidFill: {
    fill: theme.lsyPalette.primary.mainLight
  },
  lockInfoMask: {
    fill: theme.lsyPalette.primary.mainLight,
    stroke: theme.lsyPalette.primary.mainLight
  },
  rtmDisconnectedColor: {
    fill: theme.lsyPalette.secondary.main,
    stroke: theme.lsyPalette.stdRed
  },
  lockBottomInfoWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "45px"
  },
  lockStatusWrap: {
    marginLeft: "auto"
  },
  marginTop: {
    marginTop: theme.spacing(0.625)
  },
  lockIconSkeleton: {
    marginRight: "30px",
    width: "170px",
    height: "200px"
  },
  lockDetailsContainer: {
    display: "flex",
    width: "fit-content",
    flexWrap: "wrap"
  },
  lockDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  textWrapNone: {
    textWrap: "none"
  },
  alert: {
    alignItems: "center"
  },
  sera4IconButton: {
    padding: "0px"
  }
});
export default userDetailsWidgetStyle;
