import { useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEmpty, isEqual } from "lodash";

import {
  formatDateAsMMMMDDYYYYhhmmLocal,
  formatLocation,
  generateGoolgeMapsUrlPoint
} from "_helpers";
import { getFormattedLockName } from "_helpers/lock";
import { genericReducer } from "_reducers/general.reducer";
import { lockgroupService, siteService } from "_services/lockstasy";
import { getLicenseInfoFromNote, hasLockLicense } from "_services/lockstasy/helper";

import {
  Grid,
  Skeleton,
  Typography
} from "@mui/material";

import Placeholder from "_components/Helper/Placeholder";
import LockReplacementDetails from "_containers/Widgets/LockReplacementDetails";
import SummaryPopoverWidget from "./SummaryPopoverWidget";

import { 
  DescriptionOutlined,
  AccountCircle,
  LocationOn,
  Launch
} from "@mui/icons-material";

import { LockIcons } from "assets/teleporte/Icons";
import SiteIcon from "assets/teleporte/SiteIcon";

import styles from "assets/jss/widgets/lockNoteDetailsWidgetStyle";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(styles);

function LockNoteDetailsWidget(props) {
  const { data, classNameImage, isLoading, images,
    groups, sites, setGroups, setSites, canOpenLockSummary, canOpenUserSummary, canOpenSiteSummary } = props;

  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");

  const [state, setState] = useReducer(genericReducer,
    {
      isImgLoading: true,
      image: ""
    }
  );

  const [popoverState, setPopoverState] = useReducer(genericReducer,
    {
      isSummaryOpen: false,
      data: {},
      anchorEl: null,
      type: "user"
    }
  );

  const setIsSummaryOpen = (isSummaryOpen) => setPopoverState({ isSummaryOpen });

  const addGroups = (newGroups) => {
    if (isEmpty(newGroups)) {
      return;
    }
    
    const newGroupsFiltered = newGroups.filter(group => !groups.some(g => g.id === group.id));

    setGroups(newGroupsFiltered);
  };

  const addSites = (newSites) => {
    if (isEmpty(newSites)) {
      return;
    }
    
    const newSitesFiltered = newSites.filter(site => !sites.some(s => s.id === site.id));

    setSites(newSitesFiltered);
  };
  
  const fetchSite = async () => {
    const replacement_details = data?.note?.replacement_details || {};
    const lockSiteId = replacement_details.lock?.before?.collection_id;
    const replacedBySiteId = replacement_details.lock_replacement?.before?.collection_id;
    let newSites = [];

    try {
      if (lockSiteId && (isEmpty(sites) || sites.every(site => site.id !== lockSiteId))) {
        const result = await siteService.fetchSiteData({ siteId: lockSiteId });
        newSites.push({ id: result.data.id, name: result.data.name });
      }
      if (replacedBySiteId && (isEmpty(sites) || sites.every(site => site.id !== replacedBySiteId))) {
        const result = await siteService.fetchSiteData({ siteId: replacedBySiteId });
        newSites.push({ id: result.data.id, name: result.data.name });
      }

      addSites(newSites);
    } catch (e) {
      console.warn("Warning, failed to fetch site data", e);
    }
  };

  const fetchLockGroup = async () => {
    const replacement_details = data?.note?.replacement_details || {};
    const lockGroupId = replacement_details.lock?.before?.lock_group_id;
    const replacedByGroupId = replacement_details.lock_replacement?.before?.lock_group_id;
    let newGroups = [];

    try {
      if (lockGroupId && (isEmpty(groups) || groups.every(group => group.id !== lockGroupId))) {
        const result = await lockgroupService.fetchLockgroup(lockGroupId);
        newGroups.push({ id: result.data.id, name: result.data.name });
      }
      if (replacedByGroupId && (isEmpty(groups) || groups.every(group => group.id !== replacedByGroupId))) {
        const result = await lockgroupService.fetchLockgroup(replacedByGroupId);
        newGroups.push({ id: result.data.id, name: result.data.name });
      }

      addGroups(newGroups);
    } catch (e) {
      console.warn("Warning, failed to fetch lock group data", e);
    }
  };

  const fetchNoteImage = useCallback(() => {
    const img = images?.find(img => img.id === data.photos[0].id);

    if (img) {
      setState({
        image: img.data,
        isImgLoading: false
      });
    }
  }, [data?.photos, images]);

  useCustomCompareEffect(() => {
    fetchSite();
    fetchLockGroup();
  }, [data], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    setState({isImgLoading: true});
    if (!isEmpty(data?.photos)) {
      fetchNoteImage();
    } else {
      setState({
        image: "",
        isImgLoading: false
      });
    }
  }, [data?.photos, fetchNoteImage]);

  const handleSummary = async (e, type, data) => {
    try {
      setPopoverState({
        data: data,
        isSummaryOpen: true,
        type: type,
        anchorEl: e.target
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const getLocation = location => {
    return location ? 
      <span>
        <a
          href={generateGoolgeMapsUrlPoint(location)}
          className={classes.redirectLink}
          target="_blank"
          rel="noreferrer"
        >
          <b>{formatLocation(location)}</b>
          <Launch className={classes.redirectIcon}/>
        </a>
      </span> : null;
  };

  const getLockReplacementMessage = note => {
    const replacementDetails = note.replacement_details || {};
    const lock = replacementDetails.lock || {};
    const lockName = getFormattedLockName({...lock.before, id: lock.id});
    const replacementLock = replacementDetails.lock_replacement || {};
    const replacementLockName = getFormattedLockName({...replacementLock.before, id: replacementLock.id});

    return t(`resourceNotes.${note.message}`, { lock: lockName, replacementLock: replacementLockName });
  };

  const getLicense = note => {
    const { name, expiration } = getLicenseInfoFromNote(note);

    return {
      license: t(name),
      expiration: expiration ? t("resourceNotes.license.validTill", {expiration: expiration.params.till}) : ""
    };
  };

  const getSystemGeneratedMessage = () => {
    const note = data?.note || {};

    if (note.message === "lock.replacement" || note.message === "lock.replaced") {
      return getLockReplacementMessage(note);
    } else if (note.message === "license.created") {
      return t(`resourceNotes.${note.message}`, getLicense(note));
    } else {
      return t(`resourceNotes.${note.message}`);
    }
  };

  const renderMessage = () => {
    const note = data?.note || {};
    const replacementDetails = note.replacement_details || {};
    const lock = replacementDetails.lock;
    const replacementLock = replacementDetails.lock_replacement;
    const hasLicense = hasLockLicense("notes", data.related_resource);

    const message = data.system_generated ?
      getSystemGeneratedMessage() :
      hasLicense ? note.message : t("error.lock.license.required");

    return isEmpty(replacementDetails) ?
      <div className={classes.description}>
        { message }
      </div> :
      <Grid container alignItems="strech" justifyContent="space-evenly">
        <Grid item xs={12} className={classes.spaceAround}>
          <Typography variant="body2" className={classes.textAlignRight}>
            { message }
          </Typography>
          <Typography variant="body2" className={classes.textAlignRight}>
            {replacementDetails.comments}
          </Typography>
        </Grid>
        {!isEmpty(lock) && !isEmpty(replacementLock) &&
          <LockReplacementDetails
            lock={lock}
            replaceBy={replacementLock}
            type="note"
            sites={sites}
            groups={groups}
          />
        }
      </Grid>;
  };

  const renderLoading = () => {
    return <>
      <Skeleton width="100%" height={100} />
      <Skeleton width="100%" height={500} />
    </>;
  };

  const handleLockSummary = (e) => {
    if (!canOpenLockSummary) {
      return;
    }

    handleSummary(e, "lock", data?.related_resource);
  };

  const handleUserSummary = (e) => {
    if (!canOpenUserSummary) {
      return;
    }

    handleSummary(e, "user", data?.user);
  };

  const handleSiteSummary = (e) => {
    if (!canOpenSiteSummary) {
      return;
    }

    handleSummary(e, "site", data?.related_resource.site);
  };

  const renderDetails = () => {
    return !hasLockLicense("notes", data.related_resource) && !data.system_generated ?
      <Placeholder 
        message={t("error.lock.license.required")} 
        icon={<DescriptionOutlined/>}
        classNameMessage={classes.placeholderText}
        classNameIcon={classes.placeholderIcon}
        classNamePlaceholder={classes.placeholderMargin}
      /> :
      <>
        <div className={cx(classes.header, classes.cardHeader)}>
          {data?.related_resource?.name ? 
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenLockSummary ? classes.clickable : null)}
              onClick={handleLockSummary}
            >
              <LockIcons type={data.related_resource?.hardware_type} className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{getFormattedLockName(data?.related_resource)}</b>
            </div> : null}
          {data?.user ?
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenUserSummary ? classes.clickable : null)}
              onClick={handleUserSummary}
            >
              <AccountCircle className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{data?.user?.name}</b>
            </div> :  null}
          {data?.related_resource?.site?.name ? 
            <div 
              className={cx(classes.cardHeaderItem, classes.marginRight, canOpenSiteSummary ? classes.clickable : null)}
              onClick={handleSiteSummary}
            >
              <SiteIcon className={classes.cardHeaderIcon}/>
              <b className={classes.hiddenOverflow}>{data?.related_resource?.site?.name}</b>
            </div> : null}
          {data?.location ? 
            <div className={classes.cardHeaderItem}>
              <LocationOn className={classes.cardHeaderIcon}/>
              {getLocation(data?.location)}
            </div> : null}
        </div>
        <Typography className={classes.tableItemTime} variant="caption">
          {formatDateAsMMMMDDYYYYhhmmLocal(data.created_at)}
        </Typography>
        { renderMessage() }
        <div className={classes.cardBody}>
          {state.isImgLoading ?
            <Skeleton width="100%" height={500} /> :
            state.image ? 
              <div className={classes.imageContainer}>
                <img className={cx(classes.noteImage, classNameImage)} 
                  src={`data:image/jpeg;base64,${state.image}`} 
                />
              </div>
              : null}
        </div>
      </>;
  };
  
  const renderNoteInfo = () => {
    return isEmpty(data) ?
      <Placeholder 
        message={t("fallbacks.noLockNoteSelected")} 
        icon={<DescriptionOutlined/>}
        classNameMessage={classes.placeholderText}
        classNameIcon={classes.placeholderIcon}
        classNamePlaceholder={classes.placeholderMargin}
      /> :
      renderDetails();
  };

  return <div className={classes.cardContainer} data-testid="lockNoteDetailsWidget">
    <Typography variant="body1"><b>{t("widget.noteDetails")}</b></Typography>
    { isLoading ? renderLoading() : renderNoteInfo() }
    <SummaryPopoverWidget
      type={popoverState.type}
      data={popoverState.data}
      open={popoverState.isSummaryOpen}
      setOpen={setIsSummaryOpen}
      anchorEl={popoverState.anchorEl}
    />
  </div>;
}

LockNoteDetailsWidget.defaultProps = {
  canOpenLockSummary: true,
  canOpenUserSummary: true,
  canOpenSiteSummary: true
};

LockNoteDetailsWidget.propTypes = {
  classNameImage: PropTypes.string,
  data: PropTypes.object,
  images: PropTypes.array,
  isLoading: PropTypes.bool,
  groups: PropTypes.array,
  sites: PropTypes.array,
  setGroups: PropTypes.func,
  setSites: PropTypes.func,
  canOpenUserSummary: PropTypes.bool,
  canOpenLockSummary: PropTypes.bool,
  canOpenSiteSummary: PropTypes.bool
};

export default LockNoteDetailsWidget;