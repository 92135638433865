import { useCallback, useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { lockService, siteService, tagService } from "_services/lockstasy";
import { FilteredHardwareTypeOptionMenu, MAX_LOCKS_QUANTITY_FILTER } from "_constants/lock.constants";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// @mui/material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";

import CardListWidget from "_containers/Widgets/CardListWidget";
import LockCard from "_components/Lockstasy/LockCard";
import GridItem from "components/Grid/GridItem";
import Placeholder from "_components/Helper/Placeholder";

import GenericLock from "assets/teleporte/GenericLock";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/locksStyle.js";


const useStyles = makeStyles()(styles);

const filterLicenseOptions = Object.freeze([
  "BASIC",
  "ENTERPRISE",
  "KEYLESS",
  "STANDARD"
]);

function Locks(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const sera4Mode = useSelector((state) => state.auth.sera4Mode);
  const [filterOptions, setFilterOptions] = useState([]);
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const fetchTags = async (inputValue) => {
    try {
      const result = await tagService.fetchTags({ page_size: 3, search: inputValue });
      
      return result.data.map(v => v ? { id: v.id, name: v.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch tags", e);
      return [];
    }
  };

  const fetchSites = async (inputValue) => {
    try {
      const result = await siteService.fetchSites({ page_size: 5, search: inputValue });
      return result.data.map((site) => (site ? { id: site.id, name: site.name } : null));
    } catch (e) {
      console.warn("Warning, failed to fetch sites", e);
      return [];
    }
  };

  const getFilterOptions = useCallback(async () => {
    let hardwareOptions = [];
    try {
      const serverLockTypes = await lockService.fetchHardwareTypes();
      hardwareOptions = FilteredHardwareTypeOptionMenu(serverLockTypes.data["hardware_types"]);
    } catch (e) {
      console.warn("Failed to fetch hardware options: ", e);
    }

    let options = [
      {
        field: "search",
        type: "textField",
        helperText: t("label.searchByLockId", { max: MAX_LOCKS_QUANTITY_FILTER, newLine: <br/> }),
        submitOnEnter: true,
        label: t("form.search"),
        autoFocus: true
      },
      {
        field: "hardware_type",
        type: "select",
        label: t("form.showLocksOfType"),
        defaultValue: " ",
        options: [
          { name: t("label.allLocks"), value: " " },
          ...hardwareOptions
        ]
      },
      {
        field: "sort",
        type: "select",
        label: t("form.sortLocksBy"),
        defaultValue: "name",
        options: [
          { name: t("form.name"), value: "name" },
          { name: t("label.hardwareId"), value: "id" }
        ]
      }
    ];

    if (sera4Mode) {
      options.push({
        field: "license_types",
        type: "select",
        defaultValue: " ",
        label: t("widgetField.license"),
        options: [
          { name: t("label.all"), value: " " },
          ...filterLicenseOptions.map(license => ({ name: license, value: license }))
        ]
      });
    }

    if (ability.can("read", "tags")) {
      options.push({
        field: "tag_ids",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("features.tags"),
        async: true,
        isMulti: true,
        promiseOptions: fetchTags
      });
    }

    if (ability.can("read", "lock_collections")) {
      options.push({
        field: "site_id",
        type: "typeAhead",
        defaultValue: [],
        placeholder: t("features.lockCollections"),
        async: true,
        promiseOptions: fetchSites
      });
    }
    
    setFilterOptions(options);
  }, [ability, sera4Mode, t]);
  
  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const fetchLocks = async (options) => {
    if (options.tag_ids && options.tag_ids.length > 0) {
      options.tag_ids = `[${typeof options.tag_ids === "object" ? options.tag_ids.map(tag => tag.id) : options.tag_ids}]`;
    }

    if (options.site_id) {
      options.site_id = typeof options.site_id === "object" ? options.site_id.id : options.site_id;
    }

    try {
      const result = await lockService.fetchLocks(options);
      return result;
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
    }
  };

  const skeletonCard = <Card className={classes.card}>
    <div className={classes.siteCardContent}>
      <Skeleton variant="circular" className={classes.lockIcon} width={70} height={70} />
      <CardContent className={classes.cardContent}>
        <Skeleton variant="text" width={200} height={50} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CardContent>
    </div>
  </Card>;

  const lockFormatter = (data, setFilterVariables, state, setState, fetchWidgetData, createModal, setValue, reset, dismissed, updateUrlQuery) => {
    return data.map((data, index) => {
      return (<LockCard
        key={data.id}
        org={props.org}
        index={index}
        data={data}
        history={props.history}
        setFilterVariables={setFilterVariables}
        createModal={createModal}
        fetchWidgetData={fetchWidgetData}
        setValue={setValue}
        state={state}
        setState={setState}
        reset={reset}
        ability={ability}
        dismissed={dismissed}
        updateUrlQuery={updateUrlQuery}
      />
      );
    });
  };

  const getDefaultValues = () => {
    return {
      search: "",
      hardware_type: " ",
      sort: "name",
      license_types: " ",
      tag_ids: [],
      site_id: ""
    };
  };

  return (
    <div className={classes.lsyBackground}>
      <Grid container justifyContent="center">
        <GridItem className={classes.locksWidget} xs={12} md={10}>
          <CardListWidget
            skeletonCard={skeletonCard}
            fallbackData={<Placeholder message={t("fallbacks.noLocksFound")} icon={<GenericLock closed={true}/>}/>}
            org={props.org}
            paginate
            stats
            title={t("features.locks")}
            rowsPerPage={10}
            secondPaginate={true}
            enableFilter
            filterOptions={filterOptions}
            fielterDefaultValues={getDefaultValues()}
            removeEmptyOptions
            fetchData={fetchLocks}
            dataFormatter={lockFormatter}
            appBarSize="md"
            enableQueryParams
            baseUrl={props.baseUrl}
            history={props.history}
            location={props.location}
          />
        </GridItem>
      </Grid>
    </div>
  );
}

Locks.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string,
  location: PropTypes.object
};

export default Locks;
