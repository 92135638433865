import { Fragment, useContext } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { siteService, lockgroupService } from "_services/lockstasy";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";
import { lsyRouter, isBlank } from "_helpers";
import { getLicenseName } from "_services/lockstasy/helper";

// @mui/material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

//icons
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import LockIcons from "assets/teleporte/LockIcons";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/lockCardStyle.js";

const useStyles = makeStyles()(styles);

function LockCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const sera4Mode = useSelector((state) => state.auth.sera4Mode);
  const { data, type } = props;
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;

  const removeLockFromSite = async (siteId, lockId, fetchWidgetData) => {
    try {
      await siteService.removeLock({ siteId: siteId, lockId: lockId });
      dispatch(alertActions.send(t("success.removedLock")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to remove lock", e);
      dispatch(alertActions.send(t("error.removeLock"), "error"));
    }
  };

  const removeLockFromLockgroup = async (lockgroupId, lockId, fetchWidgetData) => {
    try {
      await lockgroupService.removeLock({ id: lockgroupId, lockId: lockId });
      dispatch(alertActions.send(t("success.lockGroup.removedLock")));
      fetchWidgetData();
    } catch (e) {
      console.warn("Warning, failed to remove lock", e);
      dispatch(alertActions.send(t("error.lockGroup.removeLock"), "error"));
    }
  };

  const isNewFirmwareAvailable = () => {
    if (!isBlank(data.firmware_version_next)) {
      const currentVersion = data.firmware_version_current.split(".");
      const nextVersion = data.firmware_version_next.split(".");

      return !(currentVersion[0] === nextVersion[0] && currentVersion[1] === nextVersion[1]);
    } else {
      return false;
    }
  };

  const getTypePermission = () => {
    if (type === "site") {
      return "lock_collections";
    } else if (type === "lock_group") {
      return "lock_groups";
    } else {
      return null;
    }
  };

  const handleRemoveLock = () => {
    const confirmAction = () => {
      if (type === "site") {
        removeLockFromSite(data.site.id, data.id, props.fetchWidgetData);
      } else if (type === "lock_group") {
        removeLockFromLockgroup(data.lock_group_id, data.id, props.fetchWidgetData);
      }
    };

    props.createModal({
      type: "confirm",
      confirmAction: confirmAction,
      title: t("confirmation.removeTitle"),
      description: t("confirmation.removeLock"),
      submit: true,
      confirm: t("button.confirm")
    });
  };

  return (
    <Card className={classes.card}>
      <div className={classes.lockCardContent} data-testid="lockCard">
        <Box sx={{ display: { xs: "none", sm: "block" } }}>
          <div className={classes.cardIcon}>
            <LockIcons type={data.hardware_type} defaultStyle={classes.defaultIcon} hasFirmwareUpgrade={isNewFirmwareAvailable() ? true : false} firmwareIconSize='sm' height="60px" width="60px" />
          </div>
        </Box>
        <CardContent className={classes.cardContent}>
          <p className={classes.name} onClick={() => props.history.push(lsyRouter("lock", data.id))}>
            {data.name}
          </p>
          {data.description ?
            <p className={classes.description}>
              {data.description}
            </p> : null}
          <p className={classes.keyValue}>
            {`${t("label.hardwareId")}:`}
            <span>
              {data.hardware_id}
            </span>
          </p>
          <p className={classes.keyValue}>
            {`${t("label.hardwareType")}:`}
            <span>
              {data.hardware_type}
            </span>
          </p>
          {isNewFirmwareAvailable() ? <p className={classes.keyValue}>
            {`${t("label.nextFirmwareVersion")}:`}
            <span>
              {data.firmware_version_next}
            </span>
          </p> : null}
          {type !== "site" ? <Fragment>
            {data.site?.name ?
              <Can I="read" on="lock_collections" ability={ability}>
                <p className={classes.field}>
                  {`${t("widgetField.site")}:`}
                  <span className={classes.actionLink} onClick={() => {
                    props.history.push(lsyRouter("site", data.site.id));
                  }}>
                    {data.site.name}
                  </span>
                </p> 
              </Can> : null}

            {data.lock_group_name ?
              <Can I="read" on="lock_groups" ability={ability}>
                <p className={classes.field}>
                  {`${t("label.lockGroup")}:`}
                  <span className={classes.actionLink} onClick={() => {
                    props.history.push(lsyRouter("lock_group", data.lock_group_id));
                  }}>
                    {data.lock_group_name}
                  </span>
                </p> </Can> : null}
            {sera4Mode &&
              <p className={classes.keyValue}>
                {`${t("widgetField.license")}:`}
                <span>
                  {t(getLicenseName(data.license).name)}
                </span>
              </p>
            }
            <Can I="read" on="tags" ability={ability}>
              <div className={classes.chips}>
                {data.tags?.length > 0 ? (
                  data.tags.map((tag, index) => {
                    return (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        key={`${tag.name}-${index}`}
                        title={`${t("label.showLocksTaggedWith")} ${tag.name}`}
                      >
                        <Chip
                          className={classes.chip}
                          label={<span className={classes.chipText}>{tag.name}</span>}
                          onClick={() => {
                            const newState = {
                              ...props.state.filterVariables,
                              tag_ids: [{ id: tag.id, name: tag.name }]
                            };
                            props.setState({
                              filterVariables: newState,
                              currentPage: 1,
                              filterActive: true
                            });
                            props.reset(newState);
                            props.updateUrlQuery(newState);
                          }
                          }
                        />
                      </Tooltip>
                    );
                  })
                ) : null}
              </div>
            </Can>
          </Fragment> : null}
        </CardContent>
        {type === "site" || type === "lock_group" ? <Can I="update" on={getTypePermission()} ability={ability}>
          <Tooltip
            title={data.semaphore_locked ? t("error.lock.locked") : t("button.removeLock")}
            classes={{ tooltip: classes.tooltip }}
          >
            <span>
              <IconButton
                disabled={data.semaphore_locked}
                onClick={handleRemoveLock}
                className={classes.remove}
                aria-label={t("button.removeLock")}
                size="large">
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Can> : null}
      </div>
    </Card>
  );
}

LockCard.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
  setFilterVariables: PropTypes.func,
  fetchWidgetData: PropTypes.func,
  createModal: PropTypes.func,
  keyVal: PropTypes.string,
  setValue: PropTypes.func,
  state: PropTypes.object,
  setState: PropTypes.func,
  reset: PropTypes.func,
  ability: PropTypes.object,
  updateUrlQuery: PropTypes.func
};

export default LockCard;
