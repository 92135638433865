const reportsStyle = (theme) => ({
  body: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.up("md")]: {
      padding: `${theme.spacing(5)} ${theme.spacing(2)}`
    },
    backgroundColor: theme.backgroundColor
  },
  itemBox: {
    borderStyle: "none",
    width: "100%"
  },
  header: {
    "display": "flex",
    "alignItems": "center",
    "& .MuiFormControl-root": {
      width: "fit-content"
    }
  },
  chip: {
    "margin": `${theme.spacing(0.5)} ${theme.spacing(0.75)}`,
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      width: "20px"
    },
    "& input": {
      width: "150px"
    }
  },
  removeChipClear: {
    "margin": `0 ${theme.spacing(0.75)}`,
    "padding": "0",
    "height": "27px",
    "& .MuiChip-deleteIcon": {
      display: "none",
      width: "20px"
    }
  },
  chipText: {
    "color": theme.lsyPalette.text,
    "fontSize": theme.lsyPalette.subtitle3,
    "display": "inline",
    "fontWeight": theme.fontweightHeavy,
    "& span": {
      fontWeight: theme.lsyPalette.lightWeight
    }
  },
  chipIcon: {
    margin: "0",
    width: "20px",
    height: "auto",
    paddingRight: theme.spacing(0.75)
  },
  confirmSelection: {
    border: `1px solid ${theme.lsyPalette.secondary.background}`,
    overflow: "auto",
    height: "250px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    margin: `${theme.spacing(1)} 0`,
    padding: `${theme.spacing(1)} ${theme.spacing(0.25)}`
  },
  selectionPageDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  removeItem: {
    color: theme.lsyPalette.primary.main,
    position: "absolute",
    right: "0px",
    paddingTop: "0px"
  },
  selectedNames: {
    maxWidth: "375px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  pagination: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "right",
    "& button": {
      "fontSize": theme.typography.smallFont.fontSize,
      "padding": theme.spacing(0.25),
      "& svg": {
        width: "16px",
        height: "16px"
      }
    },
    "& .MuiSelect-select": {
      fontSize: theme.typography.smallFont.fontSize,
      display: "flex",
      alignItems: "baseline",
      margin: 0,
      padding: "0"
    },
    "& svg": {
      width: "0.75em",
      height: "0.75em",
      top: "0px"
    },
    "& .MuiToolbar-root": {
      padding: 0
    },
    "& .MuiTablePagination-displayedRows": {
      fontSize: theme.typography.smallFont.fontSize
    },
    "& .MuiInputBase-root": {
      fontSize: theme.typography.smallFont.fontSize
    },
    "& .MuiTablePagination-selectLabel": {
      fontSize: theme.typography.smallFont.fontSize
    }
  },
  eventType: {
    minWidth: `calc(${theme.select.height} * 5)`,
    maxWidth: `calc(${theme.select.height} * 5)`,
    fontSize: theme.lsyPalette.subtitle3
  },
  select: {
    "fontSize": theme.typography.xsFontSize,
    "fontWeight": theme.lsyPalette.lightWeight,
    "width": "fit-content",
    "& .MuiSvgIcon-root": {
      display: "none"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0"
    },
    "& .MuiSelect-select": {
      padding: "0"
    }
  },
  selectHiddenOption: {
    display: "none"
  },
  selectOptions: {
    fontSize: theme.typography.xsFontSize
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  item: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3)
  },
  tab: {
    "textTransform": "none",
    "alignItems": "flex-start",
    "&:hover": {
      backgroundColor: theme.lsyPalette.primary.light
    },
    "&.Mui-selected": {
      backgroundColor: theme.lsyPalette.primary.light
    }
  },
  divider: {
    marginTop: "16px",
    marginBottom: "8px",
    backgroundColor: theme.lsyPalette.border.lightGrey
  },
  description: {
    color: theme.lsyPalette.primary.mainLight
  },
  listItemIcon: {
    minWidth: "auto"
  },
  clickableGrid: {
    cursor: "pointer"
  },
  table: {
    maxWidth: "calc(100vw - 50px)"
  },
  selectColumns: {
    maxHeight: "700px",
    overflow: "auto"
  },
  selectColumnsBorder: {
    borderLeftStyle: "solid",
    borderColor: theme.lsyPalette.border.lightGrey,
    borderWidth: "1px"
  },
  exportItem: {
    textAlign: "end"
  },
  clickable: {
    cursor: "pointer"
  },
  tableHeaderCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.subtitle3,
    whiteSpace: "nowrap"
  },
  tableBodyCell: {
    ...theme.tableCell,
    fontSize: theme.lsyPalette.subtitle3
  },
  textField: {
    height: "60px",
    fontSize: theme.lsyPalette.body2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  textFieldLabel: {
    fontSize: theme.lsyPalette.body2
  },
  textFieldInput: {
    fontSize: theme.lsyPalette.body2
  },
  textFieldHelper: {
    fontSize: theme.lsyPalette.subtitle3
  },
  modal: {
    maxWidth: "530px"
  },
  circularProgress: {
    color: theme.lsyPalette.stdRed
  }
});
export default reportsStyle;