import { useEffect, useState, useMemo, useRef, useCallback, useLayoutEffect, lazy, Fragment } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BroadcastChannel } from "broadcast-channel";
import { isEmpty } from "lodash";

import { config } from "_configs/server-config";
import { adminConstants } from "_constants/admin.constants";

import { history, lazyRetry, isAuthedDomain, isNotAuthedDomain } from "_helpers";

import { Notifier } from "_components";
import ModalContainer from "_components/Modal";
import { LsyAdminDataProvider } from "_contexts/LsyAdminData/LsyAdminData";
import SnackbarProvider from "_contexts/SnackbarProvider";

import { PrivateRoute } from "_components";
import EmailLink from "_containers/Auth/Login/EmailLink";
import LoginOptions from "_containers/Auth/Login/LoginOptions";
import Password from "_containers/Auth/Login/Password";
import Passwordless from "_containers/Auth/Login/Passwordless";
import PasswordReset from "_containers/Auth/Login/PasswordReset";
import LoginMob from "_containers/Auth/Login/LoginMob";
import { Logout } from "_containers/Auth/Logout";

import { Grid } from "@mui/material";
import { authActions, alertActions } from "_actions";
import { authService } from "_services";
import IdleSessionTimeout from "_components/Auth/IdleSessionTimeout";

import appRoutes from "_routes";
import Layout from "_components/Layout/Layout";

import Memberships from "_views/Memberships/Memberships";
import NotFound from "_views/NotFound";

import CssBaseline from "@mui/material/CssBaseline";

import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useDarkMode } from "_theme/Theme";
import Lockstasy from "_views/Lockstasy";

import { SlideMenuProvider } from "_components/AnimatedMenu/SlideMenu";
import { SlideMenu } from "_components/AnimatedMenu/SlideMenu";
import ScrollToTop from "_helpers/ScrollToTop";

const Admin = lazy(() => lazyRetry(() => import(/* webpackChunkName: "adminLayout" */ "_layouts/Admin.js")));

const EXTEND_SESSION_INTERVAL = 5 * 60 * 1000; // 5 minutes

const App = () => {
  const broadcastChannel = useMemo(() => new BroadcastChannel("appChannel"), []);
  const defaultLayoutProps = { xs: 12, sm: 8, md: 6 };
  const [layoutProps, setLayoutProps] = useState(defaultLayoutProps);

  const loggedInState = useSelector((state) => state.auth.loggedIn);
  const authCheckTimer = useRef(null);
  const extendSessionTimer = useRef(null);

  broadcastChannel.onmessage = (msg) => {
    const isBCOrigin = sessionStorage.getItem("isBCOrigin");
    if (isEmpty(isBCOrigin)) {
      if (msg === "REFRESH") {
        window.location.reload();
      }
    } else {
      sessionStorage.removeItem("isBCOrigin");
    }
  };

  const isAuthenticated = useCallback(() => {
    return (loggedInState && isAuthedDomain());
  }, [loggedInState]);

  // there's only 2 conditions for rewriting this, and that's based on a very simple dependency on
  //  whether it's authenticated
  const inOutRoutes = useMemo(() => {
    if (!isAuthenticated() && window.location.href.match(/\/(tenants|tap|portal)\//)){
      sessionStorage.setItem("redirectAfterLogin", window.location.href);
    }

    return isAuthenticated() ? (
      <ScrollToTop>
        <Switch>
          <PrivateRoute
            exact
            path={appRoutes.private.memberships}
            component={Memberships}
            layoutProps={layoutProps}
            setLayoutProps={setLayoutProps}
          />
          <PrivateRoute
            path={`${appRoutes.private.lockstasy}/:org?/:path?/:id?/:subPath?`}
            component={Lockstasy}
            layoutProps={layoutProps}
            setLayoutProps={setLayoutProps}
          />
          <Route exact path={appRoutes.private.logout} component={Logout} />
          <Route
            exact
            path={appRoutes.public.passwordless}
            render={props => <Passwordless {...props} shouldLogout={true}/>}
          />
          <Redirect exact from={appRoutes.public.login} to={appRoutes.private.memberships} />
          <Redirect exact from={appRoutes.public.password} to={appRoutes.private.memberships} />
          <Redirect exact from={appRoutes.public.emailLink} to={appRoutes.private.memberships} />
          <Redirect exact from="/" to={appRoutes.private.memberships} />
          <PrivateRoute
            path={appRoutes.private.adminPanel}
            s4_role={adminConstants.SERA4TAL_ADMIN}
            component={Admin}
            layoutProps={layoutProps}
            setLayoutProps={setLayoutProps}
          />
          <PrivateRoute
            path={`${appRoutes.private.tapPanel}/:server_alias?`}
            s4_role={adminConstants.TRUSTED_ADMIN}
            component={Admin}
            layoutProps={layoutProps}
            setLayoutProps={setLayoutProps}
          />
          <PrivateRoute component={NotFound} />
        </Switch>
      </ScrollToTop>
    ) : (
      <ScrollToTop>
        <Switch>
          <Route
            path={appRoutes.public.passwordReset}
            component={(props) => <PasswordReset {...props} />}
          />
          <Route
            exact
            path={appRoutes.public.passwordless}
            render={props => <Passwordless {...props} shouldLogout={false}/>}
          />
          <Route
            exact
            path={appRoutes.public.password}
            render={props => <Password {...props} renderContent="password"/>}
          />
          <Route
            exact
            path={appRoutes.public.loginMob}
            render={props => <LoginMob {...props}/>}
          />
          <Route
            exact
            path={appRoutes.public.forgotPassword}
            render={(props) => <Password {...props} renderContent="forgotPassword"/>}
          />
          <Route
            exact
            path={appRoutes.public.emailLink}
            render={props => <EmailLink {...props}/>}
          />
          <Route
            exact
            path={appRoutes.public.login}
            render={props => <LoginOptions {...props}/>}
          />
          <Redirect to={appRoutes.public.login} />
        </Switch>
      </ScrollToTop>
    );
  }, [isAuthenticated, layoutProps, setLayoutProps]);

  // fixme, add 'toggleDarkMode' to the second param at a later date
  const [theme] = useDarkMode();

  const dispatch = useDispatch();
  const themeConfig = createTheme(theme);

  useLayoutEffect(() => {
    console.debug("Teleportal version: " + config.swVersion);
  }, []);

  /* this is purely a check to see if you've switch teleporte servers */
  const checkAuthValidity = useCallback(() => {
    if (isNotAuthedDomain()) {
      // complete domain switch, push this one to a logout screen
      if (localStorage.getItem("user")) {
        console.debug("Domains switched, removing login data");
        dispatch(authActions.logoutAction(true));
        dispatch(alertActions.send("Teleporte domains switched.  You have been logged out.", "error"));
      }
    } else if (isAuthedDomain()) {
      if (isAuthenticated())
        dispatch(authActions.authCheckToken());
    }

    if (!authCheckTimer.current) {
      authCheckTimer.current = setInterval(checkAuthValidity, 5000);
    }

  }, [dispatch, isAuthenticated]);

  const extendSession = useCallback(async () => {
    if (isAuthenticated()) {
      try{
        await authService.extendSession();
      } catch (e) {
        console.warn("Failed to extend session", e?.reponse);
      }
    }

    if (!extendSessionTimer.current) {
      extendSessionTimer.current = setInterval(extendSession, EXTEND_SESSION_INTERVAL);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setTimeout(() => {
      checkAuthValidity();
    }, 1000);
    setTimeout(() => extendSession(), EXTEND_SESSION_INTERVAL);
  }, [checkAuthValidity, extendSession]);

  const getLayoutProps = () => {
    if (isAuthenticated()) {
      return layoutProps;
    }
    return defaultLayoutProps;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themeConfig}>
        <LsyAdminDataProvider>
          {isAuthenticated() && 
            <IdleSessionTimeout onLogout={() => dispatch(authActions.logout())}/>}
          <CssBaseline />
          <SnackbarProvider>
            <SlideMenuProvider>
              <SlideMenu />
              <Fragment>
                <ModalContainer />
                <Notifier />
                <Layout>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                    direction="row"
                    style={{
                      minHeight: "100vh",
                      position: "relative",
                      backgroundColor: themeConfig.lsyPalette.secondary.background
                    }}
                  >
                    <Router history={history}>
                      <Grid item {...getLayoutProps()}>
                        {inOutRoutes}
                      </Grid>
                    </Router>
                  </Grid>
                </Layout>
              </Fragment>
            </SlideMenuProvider>
          </SnackbarProvider>
        </LsyAdminDataProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
