import { useState } from "react";
import { red, grey } from "@mui/material/colors";
/* useful for debugging - adding in a random / non-native colour */
// import purple from "@mui/material/colors/purple";

// https://material-ui.com/style/color/
// tool - https://material.io/tools/color/

import {
  primaryColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react";

/* ... customisations that don"t rely on theme properties... */
const themeObject = {
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol"
    ].join(","),
    fontSize: 10,
    htmlFontSize: 10 /* 62.5% of 16px = 10px */,
    chipFontSize: 11,
    xsFontSize: 12,
    useNextVariants: true,
    color: primaryColor[0],
    smallFont: {
      fontSize: "0.8rem"
    },
    normalFont: {
      fontSize: "1.0em"
    },
    largeFont: {
      fontSize: "1.2em"
    },
    codeFont: {
      fontFamily: "monospace"
    },
    fontWeight: 300,
    fileFont: {
      fontFamily: ["Lucida Console", "Courier New", "monospace"]
    }
  },
  palette: {
    mode: "light",
    primary: {
      main: "#4C4351", //lsyGray content
      light:"#BCBCBC",
      dark:"#5C5C5C",
      contrastText: "#fff"
    },
    secondary: {
      main: grayColor[2],
      modernLightGray: "#f2f5f7"
    },
    redColor: {
      light: primaryColor[3],
      main: primaryColor[0],
      dark: primaryColor[4],
      contrastText: "#fff"
    },
    greenColor: {
      main: "#00A36C"
    },
    lightBlueColor: {
      main: "#b5e2fc" //light blue
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: grey[900],
      secondary: grey[900],
      light: grayColor[19],
      darkGrey: "#5C5C5C"
    },
    border: {
      lightGray: "#9e9e9e"
    }
  },
  lsyPalette: {
    footerText: "#666666",
    indicator: "red",
    textContrast: "white",
    text: grey[900],
    subText: grey[600],
    stdRed: "#ed1a39",
    subtitle1: "8px",
    subtitle2: "10px",
    subtitle3: "12px",
    body1: "14px",
    body2: "16px",
    body3: "18px",
    title1:"20px",
    title2: "22px",
    title3:"25px",
    title4:"30px",
    lightWeight: 300,
    mediumWeight: 500,
    heavyWeight: 700,
    link: "#337AB7",
    okColor: "#61D57B",
    rowOdd:"#f5f5f5",
    rowHover:"#ededed",
    codeBackgroundColor: "#fafafa",
    headerContrast: grey[900],
    border: {
      lightGrey: "#e6e6e6"
    },
    primary: {
      main: "#4C4351", //lsyGray content
      mainHover: "#262128",
      mainLight:"#BCBCBC",
      mainDark:"#5C5C5C",
      light:"#F5F5F5",
      background: "white"
    },
    secondary: {
      main: "white", // white background
      mainHover: "#EEEEEE",
      background: "#EEEEEE" // light gray background
    }
  },
  chartPalette: {
    color: "#2D98D4" //Bright blue
  },
  inputField: {
    height: "60px"
  },
  tableCell: {
    minWidth: "100px"
  },
  shape: {
    borderRadius: 3
  },
  select: {
    height: "50px"
  },
  icon: {
    xLarge: "100px"
  }
};

const themeOverrides = {
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: themeObject.shape.borderRadius,
        backgroundColor: themeObject.lsyPalette.secondary.background
      }
    }
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: grayColor[2]
      }
    }
  },
  MuiSnackbarContent: {
    styleOverrides: {
      message: {
        color: "white",
        fontSize: "1.2rem"
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      h1: {
        fontSize: "5rem"
      },
      body1: {
        fontSize: "16px" //1.1rem
      },
      body2: {
        fontSize: "14px" //1rem
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
        letterSpacing: "0.01071em"
      }
    }
  }
};

const themeProps = {
  MuiTextField: {
    defaultProps: {
      color: "secondary"
    }
  },
  MuiInputLabel: {
    defaultProps: {
      color: "secondary"
    }
  },
  MuiInputBase: {
    defaultProps: {
      color: "secondary"
    }
  },
  MuiSelect: {
    defaultProps: {
      color: "secondary"
    }
  },
  ...themeOverrides
};

export const useDarkMode = () => {
  const [theme, setTheme] = useState({
    ...themeObject,
    components: themeProps
  });

  const {
    palette: { mode }
  } = theme;

  const toggleDarkMode = () => {
    const updatedTheme = {
      ...theme,
      palette: {
        ...theme.palette,
        mode: mode === "light" ? "dark" : "light"
      }
    };
    setTheme(updatedTheme);
  };

  return [theme, toggleDarkMode];
};

/* defaults are at : https://material-ui.com/customization/default-theme/ */
