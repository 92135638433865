import { useState, useReducer, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useCustomCompareEffect } from "use-custom-compare";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { compactObj } from "_helpers";

//core components
import Tooltip from "@mui/material/Tooltip";
import TablePagination from "@mui/material/TablePagination";
import Skeleton from "@mui/material/Skeleton";
import Placeholder from "_components/Helper/Placeholder";

//components
import LsyTable2 from "_components/Table/LsyTable2";
import CustomModal from "_components/Modal/CustomModal";
import Badge from "_components/UI/Badge";

//icons
import { IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import VpnKeyIcon from "@mui/icons-material/VpnKeyRounded";

//styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/widgets/tableWidget2Style.js";

const useStyles = makeStyles()(styles);

function TableWidget2(props) {
  const { classes } = useStyles();
  const defaultData = [
    [
      {
        id: "Date",
        numeric: false,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Type2",
        numeric: true,
        disablePadding: false,
        label: "-"
      },
      {
        id: "Open",
        numeric: true,
        disablePadding: false,
        label: "-"
      }
    ],
    [
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      },
      {
        Date: {
          text: <Skeleton></Skeleton>
        },
        Type: {
          text: <Skeleton></Skeleton>
        },
        Type2: {
          text: <Skeleton></Skeleton>
        },
        Open: {
          text: <Skeleton></Skeleton>
        }
      }
    ]
  ];

  const [data, setData] = useState(defaultData);
  const [totalData, setTotalData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { fetchData, org, dataFormatter, title, defaultValues, filterOptions, sortMap, refresh, setRefreshKeys } = props;
  const [initialState] = useState(defaultValues);
  const language = useSelector((state) => state.locale.language);
  const { t } = useTranslation("default");

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      org: org,
      currentPage: 1,
      rowsPerPage: props.rowsPerPage,
      orderBy: props.defaultSort,
      direction: props.sortDirection,
      filterVariables: defaultValues,
      filterActive: false,
      filterOpen: false
    }
  );
  const setFilterOpen = (filterOpen) => setState({ filterOpen });
  const setSortParams = ({ orderBy, direction }) => setState({ orderBy, direction });
  const setCurrentPage = (currentPage) => setState({ currentPage });
  const { handleSubmit, reset, setValue, formState: { errors }, control, clearErrors } = useForm();

  const fetchWidgetData = async () => {
    try {
      let filterVariables = { ...state.filterVariables };
      if (props.removeEmptyOptions) {
        filterVariables = compactObj(filterVariables);
      }

      var options = {
        page: state.currentPage,
        page_size: state.rowsPerPage,
        sort_by: sortMap ? `${sortMap[state.orderBy]},${state.direction}` : null,
        ...filterVariables
      };
      const result = await fetchData(options);
      setTotalData(result.meta.pagination.total);
      
      const formattedData = dataFormatter(result.data, fetchWidgetData);
      
      setData(formattedData);
      setLoading(false);

      if (setRefreshKeys) {
        setRefreshKeys(false);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const sort = (orderBy, direction) => {
    setSortParams({
      orderBy: orderBy,
      direction: direction
    });
  };

  useCustomCompareEffect(() => {
    fetchWidgetData();
  }, [state.currentPage, state.filterVariables, language, state.org, state.orderBy, state.direction], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  useEffect(() => {
    if (refresh) {
      fetchWidgetData();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [refresh]);

  const fallbackData = (<Placeholder icon={<VpnKeyIcon/>}>{props.fallbackData}</Placeholder>);

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setCurrentPage(newPage + 1);
  };

  const resetFilter = () => {
    if (!isEqual(initialState, state.filterVariables)) {
      reset(initialState);
      setState({
        filterVariables: initialState,
        currentPage: 1,
        filterActive: false
      });
    } else {
      setFilterOpen(false);
    }
  };

  const onSubmit = (data) => {
    if (!isEqual(data, state.filterVariables)) {
      reset(data);
      setState({
        filterVariables: {
          ...state.filterVariables,
          ...data
        },
        submitting: true,
        filterActive: true,
        filterOpen: false,
        currentPage: 1
      });
    } else {
      setFilterOpen(false);
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModal
          open={state.filterOpen}
          setOpen={setFilterOpen}
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={resetFilter}
          title={t("button.filter")}
          type="formCreator"
          confirm={t("button.apply")}
          cancel={t("button.reset")}
          manualClose
          formOptions={filterOptions}
          errors={errors}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          modalStyle={classes.modal}
          submit
        />
      </form>
      <div className={classes.topBar}>
        <span className={classes.title}>
          {title}
        </span>
        {props.customButtons ? props.customButtons.map((button, index) => {
          return <Tooltip key={index} classes={{ tooltip: classes.tooltip }} title={button.label}>
            <span>
              <IconButton
                size="small"
                disabled={button.disabled}
                onClick={(e) => {
                  button.action(fetchWidgetData, e);
                }}
              >
                {button.icon}
              </IconButton>
            </span>
          </Tooltip>;
        }) : null}
        {props.enableFilter ? <Tooltip classes={{ tooltip: classes.tooltip }} title={state.filterActive ? t("label.filterApplied") : t("button.filter")}>
          <IconButton size="small" onClick={() => setFilterOpen(!state.filterOpen)}>
            <Badge invisible={!state.filterActive}>
              <FilterListIcon className={classes.icon} />
            </Badge>
          </IconButton>
        </Tooltip> : null}
        <Tooltip classes={{ tooltip: classes.tooltip }} title={t("button.refresh")}>
          <IconButton size="small" onClick={fetchWidgetData}>
            <Refresh className={classes.icon}  />
          </IconButton>
        </Tooltip>
      </div>
      {loading || data[1].length !== 0 ? (
        <LsyTable2
          data={loading ? defaultData : data}
          sort={sort}
          orderBy={state.orderBy}
          sortDirection={state.direction}
        />
      ) : (
        fallbackData
      )}
      <TablePagination
        component="div"
        count={totalData || 0}
        rowsPerPage={props.rowsPerPage}
        page={state.currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Fragment>);
}

TableWidget2.propTypes = {
  stats: PropTypes.bool,
  paginate: PropTypes.bool,
  title: PropTypes.string,
  titleAction: PropTypes.func,
  refresh: PropTypes.bool,
  icon: PropTypes.object,
  fetchData: PropTypes.func,
  org: PropTypes.string,
  dataFormatter: PropTypes.func,
  rowsPerPage: PropTypes.number,
  appBarSize: PropTypes.string,
  secondPaginate: PropTypes.bool,
  fallbackData: PropTypes.any,
  defaultSort: PropTypes.string,
  sortMap: PropTypes.object,
  sortDirection: PropTypes.string,
  customButtons: PropTypes.array,
  filterOptions: PropTypes.array,
  defaultValues: PropTypes.object,
  removeEmptyOptions: PropTypes.bool,
  enableFilter: PropTypes.bool,
  setRefreshKeys: PropTypes.func
};

export default TableWidget2;