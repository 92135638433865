import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { config } from "_configs/server-config";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";

import {
  getQueryStringValue,
  isBlank
} from "_helpers";

import Loader from "_components/Loading";

import { useDispatch } from "react-redux";
import { memberActions } from "_actions";
import { useIsMounted } from "_hooks";

// core components
import {
  Paper
} from "@mui/material";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import MembershipsList from "_containers/Membership/List";

export default function Memberships(props) {
  /* basic login memberships view doesn't include admin permissions fetch */
  // const [memberships, setMemberships] = useState([]);
  const [redirecting, setRedirecting] = useState(false);
  const isMounted = useIsMounted();

  const mainLayout = {xs: 12, sm: 8, md: 6 };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const memberships = useSelector((state) => state.memberships?.list);
  const auth = useSelector((state) => state.auth);

  const getTargetURL = (alias, targetPath) => {
    let url = `${config.tenantProtocol}${alias}.${config.domain}${config.tenantPort}` + `/#/${targetPath}`.replace(/\/+/g, "/");
    return url;
  };

  useEffect(() => {
    const subdomain = (getQueryStringValue("subdomain") || "").toLowerCase(),
      path = (getQueryStringValue("path") || "").toLowerCase();

    if (!isBlank(memberships) && !isBlank(path) && !isBlank(subdomain)) {
      for (let membership of memberships) {
        const server_alias = membership.server_alias.toLowerCase();

        if (server_alias === subdomain ) {
          const url = getTargetURL(server_alias, path);
          dispatch(memberActions.storeCurrentMembership(membership));
          setRedirecting(true);
          window.location.href = url;
          break;
        }
      }
    }

  }, [redirecting, isMounted, memberships, dispatch]);

  useLayoutEffect(() => {
    if (!isEqual(mainLayout, props.layoutProps))
      props.setLayoutProps(mainLayout);
  });

  return (
    <GridContainer>
      <GridItem xs={12}>
        {redirecting ?
          <Paper>
            <div style={{ padding: "1em" }}>
              <Loader title={t("actions.redirecting")} />
            </div>
          </Paper>
          : <MembershipsList auth={auth} history={history}/>
        }
      </GridItem>
    </GridContainer>
  );
}

Memberships.propTypes = {
  layoutProps: PropTypes.object.isRequired,
  setLayoutProps: PropTypes.func.isRequired
};