import { useCallback, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { flatten, isEmpty, isEqual } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import moment from "moment";

import { alertActions } from "_actions";
import {
  compactObj,
  formatDateAsMMMMDDYYYYhhmmLocal,
  formatDateAsYYYYMMDD,
  formatDateToAPI,
  lsyRouter
} from "_helpers";
import { useLsyHistory } from "_hooks";
import { genericReducer } from "_reducers/general.reducer";
import { workSessionService } from "_services/lockstasy";

import {
  Chip,
  Grid,
  Skeleton,
  TablePagination
} from "@mui/material";

import WorkSessionTableItem from "_components/Card/WorkSessionTableItem";
import ChipDateTimeRange from "_components/Date/ChipDateTimeRange";
import ErrorBoundary from "_components/ErrorBoundary";
import Placeholder from "_components/Helper/Placeholder";

import { List } from "@mui/icons-material";

import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/components/Table/workSessionsTableStyle";

const useStyles = makeStyles()(styles);

const defaultInitialState = {
  start_date: "",
  end_date: ""
};

function WorkSessionsTable(props) {
  const { membershipId, preview, selectedWorkSession, setSelectedWorkSession, shouldSelectFirstWorkSession } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const history = useLsyHistory();
  const { search } = history.location;
  const workSessionId = history.location.state?.workSessionId;

  const getInitialState = () => {
    const filterVars = {
      ...defaultInitialState,
      ...search ? queryString.parse(search) : {}
    };
    const filterOptions = compactObj({ ...filterVars });
    const formattedFilterOp = {
      ...filterOptions.start_date ? { start_date: moment(filterOptions.start_date, "YYYY/MM/DD") } : {},
      ...filterOptions.end_date ? { end_date: moment(filterOptions.end_date, "YYYY/MM/DD") } : {}
    };

    return { ...formattedFilterOp };
  };

  const initialState = getInitialState();

  const [state, setState] = useReducer(genericReducer,
    {
      workSessions: [],
      filterVariables: initialState,
      totalQuantity: 0,
      currentPage: 1,
      pageSize: preview ? 5 : 10,
      isLoading: true
    }
  );

  const updateUrlQuery = (newState) => {
    const newFilterVariables = {
      ...newState,
      ... newState.start_date && { start_date: formatDateAsYYYYMMDD(newState.start_date) },
      ... newState.end_date && { end_date: formatDateAsYYYYMMDD(newState.end_date) }
    };

    const filterVariables = {
      ...state.filterVariables,
      ... state.filterVariables.start_date && { start_date: formatDateAsYYYYMMDD(state.filterVariables.start_date) },
      ... state.filterVariables.end_date && { end_date: formatDateAsYYYYMMDD(state.filterVariables.end_date) }
    };
    
    if (!isEqual(filterVariables, newFilterVariables)) {
      const url = `${lsyRouter("user_work_sessions", membershipId)}?${queryString.stringify({...compactObj({ ...newFilterVariables })})}`;
      history.replace(url, history.location.state);
    }
  };

  const setFilterVariables = newFilters => {
    const newFilterVariables = { ...state.filterVariables, ...newFilters };
    updateUrlQuery(newFilterVariables);
    setState({ filterVariables: newFilterVariables });
  };
  const setDateRange = dates => setFilterVariables({ start_date: dates[0], end_date: dates[1] });
  const resetFilter = () => {
    const filterOptions = compactObj({ ...defaultInitialState });
    const formattedFilter = {
      ...filterOptions.start_date ? { start_date: moment(filterOptions.start_date, "YYYY/MM/DD") } : {},
      ...filterOptions.end_date ? { end_date: moment(filterOptions.end_date, "YYYY/MM/DD") } : {}
    };

    updateUrlQuery(formattedFilter);
    setState({ filterVariables: formattedFilter });
  };

  const fetchWorkSessions = useCallback(async () => {
    try {
      const options = {
        membership_id: membershipId,
        page_size: state.pageSize,
        page: state.currentPage,
        ...state.filterVariables.start_date &&
          {start_date: formatDateToAPI(state.filterVariables.start_date.startOf("day"))},
        ...state.filterVariables.end_date &&
          {end_date: formatDateToAPI(state.filterVariables.end_date.endOf("day"))}
      };

      const result = workSessionId ?
        await workSessionService.fetchWorkSession(workSessionId) :
        await workSessionService.fetchWorkSessions(compactObj(options));
      
      const data = flatten([result.data]);
      setState({ workSessions: data, totalQuantity: result.meta?.pagination?.total, isLoading: false });

      if (shouldSelectFirstWorkSession && !isEmpty(data)) {
        setSelectedWorkSession(data[0]);
      }
    } catch (e) {
      setState({ workSessions: [], totalQuantity: 0, isLoading: false });
      dispatch(alertActions.send(t("error.fetchWorkSessions"), "error"));
      console.warn("Warning, failed to fetch work sessions", e);
    }
  }, [membershipId, state.currentPage, state.filterVariables, state.pageSize, shouldSelectFirstWorkSession, setSelectedWorkSession, workSessionId, dispatch, t]);

  useEffect(() => {
    fetchWorkSessions();
  }, [fetchWorkSessions]);

  const handlChangePage = (event, newPage) => {
    setState({ currentPage: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setState({
      pageSize: parseInt(event.target.value),
      currentPage: 1
    });
  };

  const renderPagination = () => {
    return isEmpty(state.workSessions) || workSessionId ? null :
      <TablePagination
        className={classes.pagination}
        data-testid="pagination"
        component="div"
        count={state.totalQuantity || 0}
        rowsPerPage={state.pageSize}
        page={state.currentPage - 1}
        onPageChange={handlChangePage}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />;
  };

  const removeWorkSessionFilter = () => history.replace(lsyRouter("user_work_sessions", membershipId), { user: history.location.state.user });

  const renderFilters = () => {
    if (state.isLoading) {
      return null;
    }

    return workSessionId ?
      <Chip
        className={classes.chip}
        size="small"
        label={formatDateAsMMMMDDYYYYhhmmLocal(selectedWorkSession?.started_at_time)}
        onDelete={removeWorkSessionFilter}
      /> :
      <ChipDateTimeRange
        startDate={state.filterVariables.start_date}
        endDate={state.filterVariables.end_date}
        setDateRange={setDateRange}
        onDelete={resetFilter}
      />;
  };

  const renderLoading = () => {
    return <Grid container>
      <Grid item xs={12}>
        {[...Array(4).keys()].map((v) => {
          return <Skeleton className={cx(classes.tableItem, classes.tableSkeleton)} key={v} data-testid="skeleton" />;
        })}
      </Grid>
    </Grid>;
  };

  const renderPlaceholder = () => {
    return <Placeholder
      message={t("fallbacks.noWorkSessionsFound")}
      classNameMessage={classes.placeholderText}
      icon={<List/>}
      classNameIcon={classes.placeholderIcon}
    />;
  };

  const renderWorkSessionTableItems = () => {
    return state.workSessions.map(workSession => {
      return <Grid item key={workSession.id} className={classes.width}>
        <ErrorBoundary>
          <WorkSessionTableItem
            workSession={workSession}
            setSelectedWorkSession={setSelectedWorkSession}
            isSelected={selectedWorkSession?.id === workSession.id}
          />
        </ErrorBoundary>
      </Grid>;
    });
  };

  const renderWorkSessions = () => {
    if (state.isLoading) {
      return renderLoading();
    }

    return isEmpty(state.workSessions) ? renderPlaceholder() :
      <Grid container direction="column">
        { renderWorkSessionTableItems() }
      </Grid>;
  };

  return <ErrorBoundary>
    <Grid container justifyContent="flex-end" className={classes.spaceAround}>
      {!preview &&
        <Grid item xs={12} data-testid="filterSection">
          { renderFilters() }
        </Grid>
      }
      {!preview &&
        <Grid item data-testid="headerSection">
          { renderPagination() }
        </Grid>
      }
      <Grid item xs={12} className={classes.width} data-testid="bodySection">
        { renderWorkSessions() }
      </Grid>
      {!preview &&
        <Grid item data-testid="footerSection">
          { renderPagination() }
        </Grid>
      }
    </Grid>
  </ErrorBoundary>;
}

WorkSessionsTable.propTypes = {
  membershipId: PropTypes.string,
  preview: PropTypes.bool,
  selectedWorkSession: PropTypes.object,
  setSelectedWorkSession: PropTypes.func,
  shouldSelectFirstWorkSession: PropTypes.bool
};

export default WorkSessionsTable;