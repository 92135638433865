import { useState, useEffect, useCallback, useContext, useReducer } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { alertActions } from "_actions";
import { hasAbility } from "_helpers";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useCustomCompareMemo } from "use-custom-compare";
import { isEqual } from "lodash";
import { adminConstants } from "_constants/admin.constants";
import { Can } from "@casl/react";
import { LsyAdminDataContext } from "_contexts/LsyAdminData/LsyAdminData";

// Services
import { userService, keyService, lockService } from "_services/lockstasy";

// @mui/material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SiteIcon from "assets/teleporte/SiteIcon";
import LockGroupIcon from "assets/teleporte/LockGroupIcon";
import PersonIcon from "@mui/icons-material/Person";
import DialpadIcon from "@mui/icons-material/Dialpad";

// Components
import GridItem from "components/Grid/GridItem";
import KeyLockWizard from "_containers/Lockstasy/KeyLockWizard";
import TableWidget2 from "_containers/Widgets/TableWidget2";
import UserDetailsWidget from "_containers/Widgets/UserDetailsWidget";
import LockNoteWidget from "_containers/Widgets/LockNoteWidget";
import CustomModal from "_components/Modal/CustomModal";
import AccessWidget from "_containers/Widgets/AccessWidget";
import WorkSessionWidget from "_containers/Widgets/WorkSessionWidget";
import ErrorBoundary from "_components/ErrorBoundary";

// Styles
import { makeStyles } from "tss-react/mui";
import styles from "assets/jss/views/lockstasy/userStyle.js";
import { lsyRouter } from "_helpers";
import { convertToHex } from "_helpers";
import { fetchErrors } from "_utils";

const useStyles = makeStyles()(styles);

function User(props) {
  const { org, history } = props;
  const { classes } = useStyles();
  const { t } = useTranslation("default");
  const dispatch = useDispatch();
  const params = useParams();
  const lsyAdminDataContext = useContext(LsyAdminDataContext);
  const ability = lsyAdminDataContext.ability;
  const currentMembership = useSelector((state) => state.memberships.currentMembership);
  const [wizard, setWizard] = useState({ type: "key", open: false, key: 0, fetchWidgetData: null });
  const [keyModal, setKeyModal] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: false, data: {}, fetchWidgetData: null
    }
  );
  const [errorModal, setErrorModal] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      open: false,
      description: null
    }
  );
  const setErrorModalOpen = (open) => setErrorModal({ open });
  const [user, setUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const includeSites = hasAbility(currentMembership, "view", "lock_collections") && ability.can("read", "lock_collections");
  const isRegularUser = currentMembership.role_id === adminConstants.LSY_USER;
  const { handleSubmit, setValue, formState: { errors }, control, clearErrors, reset } = useForm();

  const formatLockId = useCallback((lockId) => {
    let formattedLockId = "";

    if (lockId) 
      formattedLockId = "0x" + convertToHex(lockId);
    
    return formattedLockId;
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type) => {
    setAnchorEl(null);
    setWizard(prev => {
      return { ...prev, type: type, open: true, key: Math.random() };
    });
  };

  const fetchUser = useCallback(async () => {
    try {
      const result = await userService.fetchUser({ id: params.id, include: "tags" });
      setUser(result.data);
    } catch (e) {
      console.warn("Warning, failed to fetch user", e);
      return [];
    }
  }, [params.id]);

  const fetchKeys = async (options = {}) => {
    if (options.lock_ids && options.lock_ids.length > 0) {
      options.lock_ids = `[${options.lock_ids.map(lock => lock.id)}]`;
    }
    if (options.start_date) {
      options.start_date = options.start_date.startOf("day");
    }
    if (options.end_date) {
      options.end_date = options.end_date.endOf("day");
    }
    try {
      const result = await keyService.fetchKeys({ ...options, membership_ids: params.id });
      return result;
    } catch (e) {
      const errors = fetchErrors(e);

      if(errors[0].detail === "error.invalid.start_date.gt.end_date") {
        dispatch(alertActions.send(t("error.invalidDates"), "error"));
      } else {
        dispatch(alertActions.send(t("error.fetchKeys"), "error"));
      }

      console.warn("Warning, failed to fetch keys", e);
      return [];
    }
  };

  const fetchLocks = async (searchInput) => {
    try {
      const result = await lockService.fetchLocks({ search_name: searchInput });
      return result.data.map(lock => lock ? { id: lock.id, name: lock.name } : null);
    } catch (e) {
      console.warn("Warning, failed to fetch locks", e);
      return [];
    }
  };

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const getFilterOptions = () => {
    const options = [
      {
        field: "status",
        type: "select",
        label: t("label.status"),
        defaultValue: "0",
        options: [
          { name: t("label.active"), value: "0" },
          { name: t("label.deactivated"), value: "1" },
          { name: t("label.expired"), value: "2" },
          { name: t("label.all"), value: " " }
        ]
      },
      {
        field: "start_date",
        type: "datePicker",
        clearable: true,
        label: t("label.startDate"),
        defaultValue: null
      },
      {
        field: "end_date",
        type: "datePicker",
        clearable: true,
        label: t("label.endDate"),
        defaultValue: null
      }
    ];

    if (!isRegularUser) {
      options.unshift({
        field: "lock_ids",
        type: "typeAhead",
        autoFocus: true,
        defaultValue: [],
        placeholder: t("widgetField.lock"),
        async: true,
        isMulti: true,
        promiseOptions: fetchLocks
      });
    }

    return options;
  };

  const canUpdateKey = () => {
    if (keyModal.data.semaphore_locked) {
      return false;
    }

    if (keyModal.type === "regular") {
      return ability.can("update", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("update", "lock_collection_keys");
    } else {
      return ability.can("update", "lock_group_keys");
    }
  };

  const form = useCustomCompareMemo(() => {
    const { data, type } = keyModal;
    if (type !== "lockGroup" && canUpdateKey()) {
      return [
        {
          field: "ticket_number",
          type: "textField",
          label: t("label.referenceNumber"),
          defaultValue: data?.ticket_number || "",
          autoFocus: true,
          placeholder: `${t("label.maxChars", { num: 50 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 50
          }
        },
        {
          field: "comments",
          type: "textField",
          label: t("label.comments"),
          defaultValue: data?.comments || "",
          placeholder: `${t("label.maxChars", { num: 100 })} [${t(
            "label.optional"
          ).toLowerCase()}]`,
          inputProps: {
            maxLength: 100
          }
        }
      ];
    } else {
      return null;
    }

  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const resetKeyDetailsFormFields = () => {
    if (form) {
      let formFields = {};
      for (const field of form) {
        formFields[field.field] = field.defaultValue;
      }

      reset(formFields);
    }
  };

  const keyDescription = useCustomCompareMemo(() => {
    const data = keyModal.data || {};
    return (<Grid className={classes.details} container direction="column">
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("label.validFrom")}: </span>
        <span>{moment(data.start_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("label.validUntil")}: </span>
        <span>{moment(data.end_date).format("lll")}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("widgetField.user")}: </span>
        <span>{`${data.user?.name} (${data.user?.email})`}</span>
      </GridItem>
      <GridItem xs={12} className={classes.keyValueItem}>
        <span>{t("widgetField.lock")}: </span>
        <span>{data.lock_name}</span>
      </GridItem>
    </Grid>);
  }, [keyModal], (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps));

  const keyFormatter = (data, fetchWidgetData) => {
    var headers = [
      { id: "type", label: t("widgetField.type") },
      { id: "lockId", label: t("form.lockId") },
      { id: "lock", label: t("label.lockName"), sortable: true },
      { id: "validFrom", label: t("label.validFrom"), sortable: true },
      { id: "validUntil", label: t("label.validUntil"), sortable: true },
      { id: "updatedAt", label: t("label.updatedAt"), sortable: true },
      { id: "issuedBy", label: t("label.issuedBy"), sortable: true },
      { id: "reference", label: t("label.reference"), sortable: false },
      { id: "comments", label: t("label.comments"), sortable: false }
    ];
  

    if (includeSites) {
      headers.splice(1, 0, {
        id: "site",
        label: t("widgetField.site"),
        sortable: true
      });
    }
    const getIcon = (v) => {
      let icon;
      if (v.lock_group_id) {
        icon = <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={v.lock_group.name}
        >
          <div className={classes.iconDiv}>
            <LockGroupIcon height="25px" width="25px" onClick={() => {
              if (ability.can("read", "lock_groups")) {
                history.push(lsyRouter("lock_group", v.lock_group_id));
              }
            }} />
          </div>
        </Tooltip>;
      } else if (v.site_id) {
        icon = <SiteIcon width="25px" height="25px" />;
      } else if (v.fsu) {
        icon = <DialpadIcon width="25px" height="25px" />;
      } else {
        icon = <PersonIcon className={classes.invisIcon} />;
      }

      return icon;
    };

    var tableData = data.map((v) => {
      var tempData = {
        type: {
          text: <div className={classes.typeIconWrap}>{getIcon(v)}</div>
        },
        site: {},
        lockId: {
          text: formatLockId(v.lock_id)
        },
        lock: {
          text: v.lock_name,
          action: ability.can("read", "locks") && !isRegularUser ? () => {
            history.push(lsyRouter("lock", v.lock_id));
          } : null
        },
        validFrom: {
          text: moment(v.start_date).local().format("lll")
        },
        validTill: {
          text: moment(v.end_date).local().format("lll"),
          key_status: v.active_status
        },
        updatedAt: {
          text: moment(v.updated_at).local().format("lll")
        },
        issuedBy: {
          text: v.issuer.name
        },
        reference: {
          text: v.ticket_number
        },
        comments: {
          text: v.comments
        },
        rowAction: () => {
          setKeyModal({ open: true, fetchWidgetData: fetchWidgetData, data: v, type: v.lock_group_id ? "lockGroup" : v.site_id ? "site" : "regular" });
        }
      };
      
      if (includeSites) {
        tempData.site = {
          text: v.site?.name || "",
          action: v.site_id && ability.can("read", "lock_collections") ? () => {
            history.push(lsyRouter("site", v.site_id));
          } : null,
          sortable: true
        };
      } else {
        delete tempData.site;
      }
      return tempData;
    });
    return [headers, tableData];
  };

  const deleteKey = async (id) => {
    try {
      await keyService.deleteKey({ keyId: id });
      keyModal.fetchWidgetData();
      dispatch(alertActions.send(t("success.deleteKey")));
    } catch (e) {
      console.warn("Warning, failed to delete key", e);
      dispatch(alertActions.send(t("error.deleteKey"), "error"));
    }
  };

  const handleDeleteKey = () => {
    if (keyModal.type === "regular") {
      deleteKey(keyModal.data.id);
    } else {
      const name = keyModal.type === "site" ? keyModal.data.site?.name : keyModal.data.lock_group?.name;
      const link = keyModal.type === "site" ? lsyRouter("site", keyModal.data.site_id) : lsyRouter("lock_group", keyModal.data.lock_group_id);
      const instruction = keyModal.type === "site" ? "instructions.manageSiteKeys" : "instructions.manageLockGroupKeys";
      const error = keyModal.type === "site" ? "error.deleteKeyTypeSite" : "error.deleteKeyTypeLockGroup";

      const description = <div>
        <p className={classes.deleteKeyMsg}>{t(error, { name: name })}</p>
        <p>{t(instruction, { link: <span className={classes.actionLink} onClick={() => history.push(link)}>{t("label.here")}</span> })}</p>
      </div>;

      setErrorModal({
        open: true,
        description: description
      });
    }
  };

  const updateKey = async (data) => {
    try {
      await keyService.updateKey({ id: keyModal.data.id, data: { key: data } });
      keyModal.fetchWidgetData();
      dispatch(alertActions.send(t("success.updateKey")));
    } catch (e) {
      console.warn("Warning, failed to update key", e);
      dispatch(alertActions.send(t("error.updateKey"), "error"));
    }
  };

  const onSubmit = (data) => {
    if (keyModal.type !== "lockGroup") {
      updateKey(data);
    }
    setKeyModal({ open: false });
  };

  const canDeleteKey = () => {
    if (keyModal.data.fsu || keyModal.data.semaphore_locked) {
      return false;
    }

    if (keyModal.type === "regular") {
      return ability.can("delete", "keys");
    } else if (keyModal.type === "site") {
      return ability.can("delete", "lock_collection_keys");
    } else {
      return ability.can("delete", "lock_group_keys");
    }
  };

  const handleKeyDown = (e, type) => {
    if (e.key === "Tab") {
      handleSelect(type);
    }
  };

  const handleKeyDownKey = (e) => handleKeyDown(e, "key");
  const handleKeyDownSiteKey = (e) => handleKeyDown(e, "userSiteKey");
  const handleKeyDownLockGroupKey = (e) => handleKeyDown(e, "lockgroupKey");

  return (
    <div className={classes.lsyBackground}>
      {wizard.open ?
        <KeyLockWizard
          key={wizard.key}
          open={wizard.open}
          setOpen={setWizard}
          fetchWidgetData={wizard.fetchWidgetData}
          org={org}
          type={wizard.type}
          user={user}
        /> : null}
      <CustomModal
        open={errorModal.open}
        setOpen={setErrorModalOpen}
        title={t("label.error")}
        type="alert"
        description={errorModal.description}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModal
          open={keyModal.open}
          setOpen={() => setKeyModal({ open: false })}
          onOpen={resetKeyDetailsFormFields}
          handleSubmit={handleSubmit(onSubmit)}
          title={t("label.keyDetails")}
          type="formCreator"
          modalStyle={classes.editKeyModal}
          description={keyDescription}
          handleCancel={handleDeleteKey}
          additionalClose={!canUpdateKey()}
          confirm={(keyModal.type !== "lockGroup" && canUpdateKey()) ? t("button.save") : null}
          cancel={t("button.deleteKey")}
          confirmDisabled={!canUpdateKey()}
          cancelDisabled={!canDeleteKey()}
          submit
          clearIcon
          manualClose
          errors={errors}
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          formOptions={form}
        />
      </form>
      <Menu
        id="create-key-options-menu"
        data-testid="create-key-options-menu"
        anchororigin={{
          vertical: "top",
          horizontal: "left"
        }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {ability.can("create", "keys") ?
          <MenuItem onKeyDown={handleKeyDownKey} onClick={() => handleSelect("key")}>{t("label.key.key")}</MenuItem> : null}
        {ability.can("create", "lock_collection_keys") ?
          <MenuItem onKeyDown={handleKeyDownSiteKey} onClick={() => handleSelect("userSiteKey")}>{t("label.lockCollectionKey")}</MenuItem> : null}
        {ability.can("create", "lock_group_keys") ?
          <MenuItem onKeyDown={handleKeyDownLockGroupKey} onClick={() => handleSelect("lockgroupKey")}>{t("label.lockGroupKey")}</MenuItem> : null}
      </Menu>
      <Grid container justifyContent="space-evenly" className={classes.container}>
        <GridItem xs={12} md={4}>
          <ErrorBoundary>
            <UserDetailsWidget user={user} org={org} history={history} isRegularUser={isRegularUser} />
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <GridItem xs={12} md={7}>
          <ErrorBoundary>
            <Can I="read" on="access_history" ability={ability}>
              <AccessWidget user={user} org={org} history={history} isRegularUser={isRegularUser} type="user"/>
            </Can>
          </ErrorBoundary>
        </GridItem>
        <Divider className={classes.divider} />
        <Grid item xs={12} md={12} className={classes.tableWidgetItem}>
          <ErrorBoundary>
            <TableWidget2
              org={org}
              key={user.membership_id}
              paginate
              refresh
              appBarSize="sm"
              title={t("label.keys")}
              rowsPerPage={8}
              fetchData={fetchKeys}
              dataFormatter={keyFormatter}
              defaultSort="updatedAt"
              sortMap={{ lock: "locks.name", site: "sites.name", validFrom: "start_date", validUntil: "end_date", issuedBy: "issuers.email", updatedAt: "updated_at" }}
              sortDirection="desc"
              fallbackData={t("fallbacks.noKeysFound")}
              customButtons={(ability.can("create", "keys") || ability.can("create", "lock_collection_keys") || ability.can("create", "lock_group_keys")) ? [{
                label: t("button.issueKey"), icon: <AddIcon className={classes.icon} />, action: (fetchWidgetData, e) => {
                  handleClick(e);
                  setWizard({ fetchWidgetData: fetchWidgetData });
                }
              }] : null}
              enableFilter
              filterOptions={getFilterOptions()}
              defaultValues={{ lock_ids: [], status: "0" }}
              removeEmptyOptions
            />
          </ErrorBoundary>
        </Grid>
        <Divider className={classes.divider} />
        <GridItem xs={12} md={6}>
          <ErrorBoundary>
            <LockNoteWidget user={user} org={org} history={history} isRegularUser={isRegularUser} type="user"/>
          </ErrorBoundary>
        </GridItem>
        <Divider orientation="vertical" flexItem />
        <Divider sx={{ display: { md: "none", xs: "block" } }} className={classes.divider} />
        <GridItem xs={12} md={5}>
          <ErrorBoundary>
            <Can I="read" on="work_sessions" ability={ability}>
              <WorkSessionWidget user={user} org={org} history={history} isRegularUser={isRegularUser} />
            </Can>
          </ErrorBoundary>
        </GridItem>
      </Grid>
    </div>
  );
}

User.propTypes = {
  history: PropTypes.object,
  org: PropTypes.string,
  baseUrl: PropTypes.string
};

export default User;
