import { debounce } from "lodash";

import { TYPING_WAIT_TIME } from "_helpers/utility";
import { accountService } from "_services/admin/account.service";

export const fetchAccountsWithInput = debounce((inputValue, callback) => {
  let options = {};

  if (typeof inputValue === "string") {
    options = { email: inputValue };
  } else {
    options = { ...inputValue };
  }

  if (options.email) {
    options.email = encodeURIComponent(options.email);
  }

  const callbackFunc = callback ? callback : data => data;

  accountService.fetchAccounts({ page_index: 1, page_size: 5, ...options })
    .then((result) => {
      const users = result.data.map(u => u ? { id: u.id, name: `${u.first_name} ${u.last_name} (${u.email})` } : null);

      return callbackFunc(users);
    })
    .catch((e) => {
      console.warn("Warning, failed to fetch users with given input", e);
      return callbackFunc([]);
    });
}, TYPING_WAIT_TIME);

export async function setUserPreference(userId, data){
  try {
    await accountService.updatePreferences({ id: userId, data: { options: { preferences: data } } });
  } catch (e) {
    console.warn("Failed to set preference", e);
  }
}